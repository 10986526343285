import moment from 'moment';

import FlightMacros from '@libs/FlightMacros';
import Storage from '@libs/Storage';
import Subscriber from '@libs/Subscriber';

import AnalyticsService from '@master/Services/AnalyticsService';
import RequestLimiter from '@libs/RequestLimiter';
import SaveService from '@master/Services/SaveService';

import { STATUS, TYPE, VIEW } from '@master/constants';

const RESPONSE = {
  ACCESS_DENIED: 'access_denied',
  NEW: 'new',
  NOT_FOUND: 'not_found',
  PUBLISHING: 'publishing',
};

class FlightService extends Subscriber {
  storage;
  creatives = new CreativesCache(this.id);
  macros = new FlightMacros();

  load() {
    this.data = undefined;

    const flight_id = this.getFlightId();

    if (flight_id == null) {
      return this.#customResponse(RESPONSE.NOT_FOUND);
    }

    if (flight_id.startsWith('new')) {
      return this.#customResponse(RESPONSE.NEW);
    }

    this.storage = new Storage(flight_id);

    const flight = this.get();

    return this.$http
      .put(`v2/flights/${flight_id}/full/sync`, { flight }, { notification: false })
      .then(response => {
        const flight = response.flight;
        this.macros.create(flight.statements);

        this.data = {
          flight,
          macros: this.macros.get(),
        };

        if (response?.flight) {
          this.storage.set(response.flight);
        }
      })
      .catch(error => {
        if (error?.code === 423) {
          this.#customResponse(RESPONSE.ACCESS_DENIED);
        } else {
          this.#customResponse(RESPONSE.NOT_FOUND);
        }
      })
      .finally(() => {
        return;
      });
  }

  get() {
    return this.storage?.get();
  }

  async save() {
    const flight_id = this.getFlightId();

    if (flight_id == null) {
      return;
    }

    const flight = this.get();

    if (flight == null) {
      return;
    }

    await this.$http
      .post(`v2/flights/${flight_id}/full/sync`, { flight }, { notification: false })
      .then(response => {
        const flight = response.flight;
        this.macros.create(flight.statements);

        this.data = {
          flight,
          macros: this.macros.get(),
        };

        if (response?.flight) {
          this.storage?.set(response.flight);
          SaveService.flight.setTimestamp(response.flight.updated_on);
        }
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }

  discard() {
    const flight_id = this.getFlightId();
    const campaign_id = this.getCampaignId();

    if (flight_id == null || campaign_id == null) {
      return;
    }

    this.#goToFlight(campaign_id);

    this.$http
      .delete(`v2/campaigns/${campaign_id}/flights`, {
        flight_ids: [flight_id],
      })
      .then(() => {
        this.#customResponse(RESPONSE.NEW);
      });
  }

  upsert() {
    const campaign_id = this.getCampaignId() ?? AnalyticsService?.getCampaignId();

    if (campaign_id == null) {
      return Promise.resolve(false);
    }

    return this.$http
      .get(`v2/campaigns/${campaign_id}/flights`)
      .then(response => {
        this.#goToFlight(campaign_id, response?.[0]?.id);
        return true;
      })
      .catch(() => {
        return false;
      });
  }

  update(flight) {
    if (flight == null) {
      return;
    }

    flight = {
      ...flight,
      updated_on: moment().unix(),
    };

    this.macros.create(flight.statements);

    this.data = {
      flight,
      macros: this.macros.get(),
    };

    this.storage?.set(flight);
    this.#lock();
  }

  publish() {
    const flight = this.get();

    if (flight == null) {
      return Promise.resolve();
    }

    this.#togglePublishing(true);

    const payload = {
      ...flight,
      live: null,
    };

    return this.$http
      .post(`v2/flights/${flight.id}/publish`, { flight: payload })
      .then(({ flight }) => {
        this.update(flight);
        this.#togglePublishing(false);
        return flight;
      })
      .catch(error => {
        this.#togglePublishing(false);
        throw error;
      });
  }

  /**
   * @description Generate random unique id for new flight components (from https://gist.github.com/kenng/3fa1347bd0fe34866f28959fec86d784)
   * @returns {string} id as new-xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
   * @example const id = FlightService.id();
   */
  id() {
    return 'new-xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, char => {
      if ('new'.includes(char)) return char;
      const constant = (Math.random() * 16) | 0;
      return (char === 'x' ? constant : (constant & 0x3) | 0x8).toString(16);
    });
  }

  hasValidId(id) {
    return id != null && id !== '' && !id?.startsWith('new-');
  }

  getFlightId() {
    return this.router?.history?.current?.params?.flight_id;
  }

  getCampaignId() {
    return this.router?.history?.current?.params?.campaign_id;
  }

  /**
   * @description Lock flight to prevent multiple users from editing the same flight
   */
  #lock() {
    const flight_id = this.getFlightId();

    if (flight_id == null) {
      return;
    }

    RequestLimiter.hook(
      'lock-flight',
      () => {
        this.$http.put(`v2/flights/${flight_id}/lock`, {}, { flight: false }).catch(() => {
          /** suppress errors */
        });
      },
      10,
    );
  }

  #togglePublishing(state = true) {
    this.data = {
      ...(this.data ?? {}),
      [RESPONSE.PUBLISHING]: state,
    };
  }

  #customResponse(key) {
    this.data = {
      [key]: true,
    };
    this.storage?.remove();
  }

  #goToFlight(campaign_id, flight_id = 'new') {
    this.router.push({ name: VIEW.FLIGHT, params: { campaign_id, flight_id } });
  }
}

class CreativesCache {
  #cache = new Map();
  #id;

  constructor(id) {
    this.#id = id;
  }

  get(creative_id) {
    return this.#cache.get(creative_id);
  }

  set(creatives) {
    for (const creative of creatives ?? []) {
      this.#cache.set(creative.creative_id, {
        creative_id: creative.creative_id,
        id: this.#id(),
        live_id: creative.live_id,
        name: creative.name,
        priority: creative.priority ?? 0,
        status: creative?.status ?? STATUS.DRAFT,
        type: creative?.type ?? TYPE.INFEED,
        thumbnail_url: creative?.thumbnail_url,
      });
    }
  }
}

export default new FlightService();
