export default {
  mock_user: undefined,

  data: new Map(),

  set(key, value) {
    this.data.set(key, value);
  },

  get(key) {
    return this.data.get(key);
  },

  registrationFields() {
    const fields = {
      email: {
        label: 'Business Email *',
        type: 'email',
        value: '',
        placeholder: 'someone@domain.com',
      },
      password: {
        label: 'Password *',
        type: 'password',
        value: '',
        placeholder: 'password',
        hidden: true,
      },
    };

    return fields;
  },

  createMockRegistration() {
    const ts = Date.now();
    this.mock_user = {
      email: `${ts}@nexd.localhost`,
      password: 'olenerik',
    };
    return this.mock_user;
  },

  getMockRegistration() {
    return this.mock_user;
  },
};
