import UserService from '@master/Services/UserService';
import TemplatesService from '@master/Services/TemplatesService';

import { TYPE, DEVICE, SIZE, DSP, STATUS, TEMPLATE } from '@master/constants';

function groupCreativesByType(creatives) {
  const output = {
    meta: {
      has_google_ads_creatives: false,
    },
    creatives: {
      infeed: {
        label: 'Standard Infeed',
        creatives: [],
      },
      interstitial: {
        label: 'Interstitial',
        creatives: [],
      },
      interscroller: {
        label: 'Interscroller',
        creatives: [],
      },
      expandable: {
        label: 'Expandable',
        creatives: [],
      },
      dynamic_endcard: {
        label: 'Dynamic endcard',
        creatives: [],
      },
      lightbox: {
        label: 'Lightbox',
        creatives: [],
      },
      responsive: {
        label: 'Responsive',
        creatives: [],
      },
      vast: {
        label: 'In-stream (VAST)',
        creatives: [],
      },
      vpaid: {
        label: 'In-stream (VPAID)',
        creatives: [],
      },
      skin: {
        label: 'Skin',
        creatives: [],
      },
    },
  };

  for (const creative of creatives) {
    // set some metainfo
    if (creative.dsp === DSP.GOOGLEADS) output.meta.has_google_ads_creatives = true;

    const type = creative.type;

    // sort creatives
    if (type === TYPE.RESPONSIVE_INFEED) output.creatives.responsive.creatives.push(creative);
    else if (type === TYPE.INFEED || type === TYPE.HTML5_INFEED) output.creatives.infeed.creatives.push(creative);
    else if (type === TYPE.INTERSTITIAL || type === TYPE.HTML5_INTERSTITIAL) output.creatives.interstitial.creatives.push(creative);
    else if (type === TYPE.INTERSCROLLER || type === TYPE.HTML5_INTERSCROLLER) output.creatives.interscroller.creatives.push(creative);
    else if (type === TYPE.VAST) output.creatives.vast.creatives.push(creative);
    else if (type === TYPE.VPAID || type === TYPE.HTML5_VPAID) output.creatives.vpaid.creatives.push(creative);
    else if (type === TYPE.SKIN) output.creatives.skin.creatives.push(creative);
    else if (type === TYPE.EXPANDABLE_INFEED || type === TYPE.EXPANDABLE_INTERSTITIAL) output.creatives.expandable.creatives.push(creative);
    else if (type === TYPE.DYN_END_INFEED || type === TYPE.DYN_END_INTERSTITIAL) output.creatives.dynamic_endcard.creatives.push(creative);
    else if (type === TYPE.LIGHTBOX_INFEED || type === TYPE.LIGHTBOX_INTERSTITIAL) output.creatives.lightbox.creatives.push(creative);
  }

  return output;
}

function infeedPlacementSizes() {
  return [
    { value: '-1', label: 'Type or select size' },
    {
      value: '300x250',
      label: '300x250',
      description: '(medium rectangle)',
      width: 300,
      height: 250,
    },
    {
      value: '300x600',
      label: '300x600',
      description: '(half page)',
      width: 300,
      height: 600,
    },
    {
      value: '320x480',
      label: '320x480',
      description: '(mobile tower)',
      width: 320,
      height: 480,
    },
    {
      value: '320x320',
      label: '320x320',
      description: '(large square)',
      width: 320,
      height: 320,
    },
    {
      value: '320x50',
      label: '320x50',
      description: '(mobile leaderboard)',
      width: 320,
      height: 50,
    },
    {
      value: '728x90',
      label: '728x90',
      description: '(leaderboard)',
      width: 728,
      height: 90,
    },
    {
      value: '300x50',
      label: '300x50',
      description: '(mobile)',
      width: 300,
      height: 50,
    },
    {
      value: '970x250',
      label: '970x250',
      description: '(billboard)',
      width: 970,
      height: 250,
    },
    {
      value: '300x300',
      label: '300x300',
      description: '(square)',
      width: 300,
      height: 300,
    },
    {
      value: '300x150',
      label: '300x150',
      description: '(junior)',
      width: 300,
      height: 150,
    },
    {
      value: '336x280',
      label: '336x280',
      description: '(large rectangle)',
      width: 336,
      height: 280,
    },
    {
      value: '320x100',
      label: '320x100',
      description: '(large mobile)',
      width: 320,
      height: 100,
    },
    {
      value: '160x600',
      label: '160x600',
      description: '(wide skyscraper)',
      width: 160,
      height: 600,
    },
    {
      value: '970x90',
      label: '970x90',
      description: '(super leaderboard)',
      width: 970,
      height: 90,
    },
  ];
}

function instreamPlacementSizes() {
  return [
    { value: '-1', label: 'Type or select size' },
    {
      value: '1920x1080',
      label: '1920x1080',
      description: '(16:9 Landscape FHD)',
      width: 1920,
      height: 1080,
    },
    {
      value: '1440x1080',
      label: '1440x1080',
      description: '(16:9 Landscape HDV)',
      width: 1440,
      height: 1080,
    },
    {
      value: '1280x720',
      label: '1280x720',
      description: '(16:9 Landscape WXGA-H)',
      width: 1280,
      height: 720,
    },
    {
      value: '854x480',
      label: '854x480',
      description: '(16:9 Landscape FWVGA)',
      width: 854,
      height: 480,
    },
    {
      value: '640x360',
      label: '640x360',
      description: '(16:9 Landscape VGA)',
      width: 640,
      height: 360,
    },
    {
      value: '1080x1920',
      label: '1080x1920',
      description: '(9:16 Portrait FHD)',
      width: 1080,
      height: 1920,
    },
    {
      value: '1080x1440',
      label: '1080x1440',
      description: '(9:16 Portrait HDV)',
      width: 1080,
      height: 1440,
    },
    {
      value: '720x1280',
      label: '720x1280',
      description: '(9:16 Portrait WXGA-H)',
      width: 720,
      height: 1280,
    },
    {
      value: '480x854',
      label: '480x854',
      description: '(9:16 Portrait FWVGA)',
      width: 480,
      height: 854,
    },
    {
      value: '360x640',
      label: '360x640',
      description: '(9:16 Portrait VGA)',
      width: 360,
      height: 640,
    },
    {
      value: '1920x1920',
      label: '1920x1920',
      description: '(1:1 Square HQ)',
      width: 1920,
      height: 1920,
    },
    {
      value: '1440x1440',
      label: '1440x1440',
      description: '(1:1 Square HDV)',
      width: 1440,
      height: 1440,
    },
    {
      value: '1280x1280',
      label: '1280x1280',
      description: '(1:1 Square WXGA-H)',
      width: 1280,
      height: 1280,
    },
    {
      value: '854x854',
      label: '854x854',
      description: '(1:1 Square FWVGA)',
      width: 854,
      height: 854,
    },
    {
      value: '640x640',
      label: '640x640',
      description: '(1:1 Square VGA)',
      width: 640,
      height: 640,
    },
  ];
}

function newCreative(options = { type: TYPE.INFEED, device: DEVICE.MOBILE, imported: null }) {
  return new Promise(async resolve => {
    let template = null;
    let template_id = null;

    if (options.type === TYPE.DYN_END_INFEED) {
      template_id = TEMPLATE.DYN_END_INFEED_DEFAULT;
    } else if (options.type === TYPE.DYN_END_INTERSTITIAL) {
      template_id = TEMPLATE.DYN_END_INTERSTITIAL_DEFAULT;
    }

    if (template_id != null) {
      template = await TemplatesService.get(template_id);
    }

    const settings = {
      cta: {
        assets: {},
      },
      tag: {
        placement: null,
      },
    };

    if (options?.ctv_format) {
      settings.ctv_format = options.ctv_format;
    }

    const creative = {
      // null ID means its new, so component will know to show it bit different
      creative_id: 'new-' + Date.now(),
      name: 'New creative',
      width: options.width ?? '',
      height: options.height ?? '',
      status: STATUS.DRAFT,
      template,
      template_id,
      layout_id: null,
      type: options.type ?? 0,
      device: options.device ?? 0,
      custom: 0,
      updated_on: null,
      created_on: null,
      dsp: UserService.getSetting('default.dsp') || 'None',
      assets: {},
      settings,
      tag: {
        placement: null,
      },
      imported: options.imported ?? null,
      folder_id: options.folder_id ?? null,
    };
    resolve(creative);
  });
}

function getNewCreativeOptions(type) {
  switch (type) {
    case 'infeed':
      return { type: TYPE.INFEED, device: DEVICE.MOBILE };
    case 'interstitial':
      return {
        type: TYPE.INTERSTITIAL,
        device: DEVICE.MOBILE,
        width: SIZE.INTERSTITIAL.WIDTH,
        height: SIZE.INTERSTITIAL.HEIGHT,
      };
    case 'interscroller':
      return { type: TYPE.INTERSCROLLER, device: DEVICE.MOBILE };
    case 'expandable':
      return { type: TYPE.EXPANDABLE_INFEED, device: DEVICE.MOBILE };
    case 'endcard':
      return { type: TYPE.DYN_END_INFEED, device: DEVICE.MOBILE };
    case 'lightbox':
      return { type: TYPE.LIGHTBOX_INFEED, device: DEVICE.MOBILE };
    case 'responsive':
      return {
        type: TYPE.RESPONSIVE_INFEED,
        device: DEVICE.QUANTUM,
        width: 640,
        height: 640,
      };
    case 'vast':
      return {
        type: TYPE.VAST,
        device: DEVICE.VAST,
        width: SIZE.DEFAULT.VAST.WIDTH,
        height: SIZE.DEFAULT.VAST.HEIGHT,
      };
    case 'ctv_vast':
      return {
        type: TYPE.VAST,
        device: DEVICE.VAST,
        width: 3840,
        height: 2160,
        ctv_format: true,
      };
    case 'vpaid':
      return {
        type: TYPE.VPAID,
        device: DEVICE.VPAID,
        width: SIZE.DEFAULT.VAST.WIDTH,
        height: SIZE.DEFAULT.VAST.HEIGHT,
      };
    default:
      return { type, device: DEVICE.MOBILE };
  }
}

export { groupCreativesByType, infeedPlacementSizes, instreamPlacementSizes, newCreative, getNewCreativeOptions };
