<template lang="pug">
div.subscription-plans(v-if="subscription && plans")
  div.row
    div.col-6
      div
    div.col-6(v-for="(plan, index) of plans" :key="plan.plan_id")
      div.text-center
        h2.mb-8 {{plan.name}}
        h3.mb-8 {{plan.price}}/mo
        Button(
          type="primary"
          :label="can_choose_anything ? 'Choose' : (active_plan_index < index ? 'Upgrade' : 'Activated')"
          :disabled="!can_choose_anything && active_plan_index >= index"
          @click="choosePlan(plan.plan_id)"
        )

  div.row.flex-align-center(v-for="(feature, index) of features" :key="feature.feature_id")
    div.col-6
      h2.mb-4 {{feature.name}}
      small {{feature.description}}&nbsp;
    div.col-6.text-center(v-for="plan of plans" :key="plan.plan_id")
      i.nexd-icon-16-check-mini.color-success(v-if="plan.features[index].value === 'true'" aria-hidden="true")
      i.nexd-icon-16-close-small.color-danger(v-else-if="plan.features[index].value === 'false'" aria-hidden="true")
      span(v-else-if="!isNaN(parseInt(plan.features[index].value))") {{parseInt(plan.features[index].value) | NumberFilter}}
      span(v-else) {{plan.features[index].value}}

</template>

<style lang="scss">
.subscription-plans {
  .row {
    padding: 0.5rem 0;
    &:nth-child(even) {
      background-color: #f7f9fc;
    }
  }
}
</style>

<script>
import Button from '@master/UI/Buttons/Button.vue';
import { SUBSCRIPTION } from '@master/constants';

export default {
  name: 'PlanSelection',

  components: {
    Button,
  },

  props: {
    group: Object,
    subscription: Object,
  },

  computed: {
    can_choose_anything() {
      return this.subscription.isTrial() || this.subscription.noPlan();
    },

    active_plan_index() {
      if (!this.plans) return 0;
      return this.plans.findIndex(plan => (plan.plan_id = this.subscription.plan_id));
    },
  },

  data() {
    return {
      plans: null,
    };
  },

  created() {
    const currency = this.$user.getCurrency();
    const path = `public/subscriptions?currency=${currency}`;

    this.$http.get(path).then(response => {
      this.plans = [];

      for (const plan of response) {
        // exclude non subscription lans
        if ([SUBSCRIPTION.ENTERPRISE, SUBSCRIPTION.INTEGRATION, SUBSCRIPTION.PAYASYOUGO].includes(plan.plan_id)) continue;

        this.plans.push({
          plan_id: plan.plan_id,
          name: plan.name,
          price: plan.price,
          description: plan.description,
          features: plan.features,
        });
      }

      this.features = this.plans[0].features;
    });
  },

  methods: {
    choosePlan(selected_plan_id) {
      this.$emit('change', selected_plan_id);
    },
  },
};
</script>
