<template lang="pug">
FinalizeRegistration(v-if="finalize_registration")
div#app(v-else)
  Notifications
  Navbar(v-if="show_navbar" ref="navbar" :app-name="app_name")
  // sidebar before content
  Sidebar(v-if="show_sidebar")
  // currently show trial only if trial is ended and no cc is added
  TrialEndScreen(v-if="has_trial_ended && !has_active_cc" @close="closeTrialEndModal")
  div#content
    NavbarNotificationBanner(v-if="show_navbar")
    router-view(:key="route_key")
</template>

<script>
import AdBlockService from '@master/Services/AdBlockService';
import Notifications from '@master/UI/Notifications.vue';
import Navbar from '@master/UI/Navbar/Navbar.vue';
import NavbarNotificationBanner from '@master/UI/Navbar/NavbarNotificationBanner.vue';
import Sidebar from '@root/src/global/sidebar/global/Component.vue';

import FinalizeRegistration from '@root/src/global/Modals/FinalizeRegistration.vue';
import TrialEndScreen from '@root/src/global/Modals/TrialEndScreen.vue';

import { checkForLocale } from '@helpers/Global';

export default {
  name: 'app',
  components: {
    Notifications,
    Navbar,
    NavbarNotificationBanner,
    Sidebar,
    FinalizeRegistration,
    TrialEndScreen,
  },

  data() {
    return {
      app_name: null,
      app_key: null,
      auth_required: null,
      show_sidebar: false,
      show_navbar: false,
      route_key: this.$route.fullPath,
      has_trial_ended: false,
      has_active_cc: false,
      user: null,
      finalize_registration: false,
    };
  },

  created() {
    this.$user.subscribe(user => {
      this.user = user;
      this.has_trial_ended = this.$user.hasTrialEnded();
      this.has_active_cc = this.$user.groupHasActiveCreditCard();
      this.finalize_registration = this.$user.requiresRegistrationFinalize();

      checkForLocale(this.$user.getGroup());
    }, this);
    this.init();
  },

  methods: {
    init() {
      this._defaults();
      if (this.$route.meta == null) {
        document.body.className = null;
        return;
      }

      if (this.$route.meta.app_name != null) {
        this.app_name = this.$route.meta.app_name;
      }

      if (this.$route.meta.app_key != null) {
        this.app_key = this.$route.meta.app_key;
        document.body.className = this.app_key;
      }

      if (this.$route.meta.requires_auth != null) {
        this.auth_required = this.$route.meta.requires_auth;
      }

      if (this.$route.meta.show_navbar != null) {
        this.show_navbar = this.$route.meta.show_navbar;
      }

      if (this.$route.meta.show_sidebar != null) {
        this.show_sidebar = this.$route.meta.show_sidebar;
      }

      if (this.$route.meta.validate_adblock) {
        AdBlockService.validate();
      }

      if (this.app_key === 'analytics' || this.app_key === 'login') {
        // analytics app has its own :key for inner routes because of the sepperate sidebar
        this.route_key = this.app_key;
      } else {
        // all other apps have the same key as route full path, to force reloading components
        this.route_key = this.$route.path;
      }

      if (window._christmas_8bit) {
        document.body.className += ' christmas';
      }
    },

    closeTrialEndModal() {
      this.has_trial_ended = false;
    },

    _defaults() {
      this.app_name = '';
      this.app_key = null;
      this.auth_required = false;
      this.show_navbar = false;
    },
  },

  watch: {
    $route() {
      this.init();
    },
  },
};
</script>
