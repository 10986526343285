<script>
import StatusTraits from '@master/Traits/StatusTraits.vue';
import { TYPE, DEVICE, CREATIVE_CORE } from '@master/constants';

export default {
  methods: {
    isInfeed(type = null) {
      if (type == null) {
        type = this.creative.type;
      }
      return type === TYPE.INFEED;
    },

    isSticky(creative = null) {
      if (creative == null) {
        creative = this.creative;
      }

      return creative.type === TYPE.INFEED && creative?.settings?.tag?.placement != null && creative.settings.tag.placement === 'sticky';
    },

    isFullscreen(type = null) {
      if (type == null) {
        type = this.creative.type;
      }
      return type === TYPE.INTERSTITIAL;
    },

    isExpandable(type = null) {
      if (type == null) {
        type = this.creative.type;
      }
      const expandables = [TYPE.EXPANDABLE_INFEED, TYPE.EXPANDABLE_INTERSTITIAL];
      return expandables.indexOf(type) !== -1;
    },

    isDynamicEndcard(type = null) {
      if (type == null) {
        type = this.creative.type;
      }
      const dynamic_endcards = [TYPE.DYN_END_INFEED, TYPE.DYN_END_INTERSTITIAL];
      return dynamic_endcards.indexOf(type) !== -1;
    },

    isLightbox(type = null) {
      if (type == null) {
        type = this.creative.type;
      }
      const lightboxes = [TYPE.LIGHTBOX_INFEED, TYPE.LIGHTBOX_INTERSTITIAL];
      return lightboxes.indexOf(type) !== -1;
    },

    isBinded(type = null) {
      if (type == null) {
        type = this.creative.type;
      }
      return this.isExpandable(type) || this.isDynamicEndcard(type) || this.isLightbox(type);
    },

    isInterscroller(type = null) {
      if (type == null) {
        type = this.creative.type;
      }
      return type === TYPE.INTERSCROLLER || type === TYPE.HTML5_INTERSCROLLER;
    },

    isTemplateInterscroller(template = null) {
      if (template == null) {
        template = this.creative.template;
      }
      if (template != null && template.type != null) {
        return template.type === TYPE.INTERSCROLLER;
      }
      return false;
    },

    isSomeSortOfInfeed(type = null) {
      if (type == null) {
        type = this.creative.type;
      }
      const infeed_types = [TYPE.INFEED, TYPE.INTERSCROLLER, TYPE.EXPANDABLE_INFEED, TYPE.DYN_END_INFEED, TYPE.LIGHTBOX_INFEED, TYPE.RESPONSIVE_INFEED, TYPE.HTML5_INFEED];
      return infeed_types.indexOf(type) !== -1;
    },

    isSomeSortOfFullscreen(type = null) {
      if (type == null) {
        type = this.creative.type;
      }
      const fullscreen_types = [TYPE.INTERSTITIAL, TYPE.EXPANDABLE_INTERSTITIAL, TYPE.DYN_END_INTERSTITIAL, TYPE.LIGHTBOX_INTERSTITIAL, TYPE.HTML5_INTERSTITIAL];
      return fullscreen_types.indexOf(type) !== -1;
    },

    isVast(type = null) {
      if (type == null) {
        type = this.creative.type;
      }
      return type === TYPE.VAST;
    },

    isCTVVast(creative = null) {
      creative = creative ?? this.creative;
      return creative.type === TYPE.VAST && creative?.settings?.ctv_format;
    },

    isVpaid(type = null) {
      if (type == null) {
        type = this.creative.type;
      }
      return [TYPE.VPAID, TYPE.HTML5_VPAID].includes(type);
    },

    isSkin(type = null) {
      if (type == null) {
        type = this.creative.type;
      }
      return type === TYPE.SKIN;
    },

    isADImport(creative = null) {
      if (creative == null) {
        creative = this.creative;
      }
      return creative.template_id == null && creative.parent_id != null;
    },

    isParallax(creative = null) {
      if (creative == null) {
        creative = this.creative;
      }
      const parallax_templates = ['eTEFphYL', '8ygffm3JF2G9', 'NTPjRPJa'];
      return parallax_templates.indexOf(creative.template_id) !== -1;
    },

    isMobile(device = null) {
      if (device == null) {
        device = this.creative.device;
      }
      return device === DEVICE.MOBILE;
    },

    isDesktop(device = null) {
      if (device == null) {
        device = this.creative.device;
      }
      return device === DEVICE.DESKTOP;
    },

    isVideo(type = null) {
      if (type == null) {
        type = this.creative.type;
      }
      return this.isVast(type) || this.isVpaid(type);
    },

    isQuantum(type = null) {
      if (type == null) {
        type = this.creative.type;
      }
      return type === TYPE.RESPONSIVE_INFEED;
    },

    isQuantumDevice(device = null) {
      if (device == null) {
        device = this.creative.device;
      }
      return device === DEVICE.QUANTUM;
    },

    isFeed(device = null) {
      if (device == null) {
        device = this.creative.device;
      }
      return device === DEVICE.FEED;
    },

    isHTML5(type = null) {
      if (type == null) {
        type = this.creative.type;
      }
      const types = [TYPE.HTML5_INFEED, TYPE.HTML5_INTERSTITIAL, TYPE.HTML5_INTERSCROLLER, TYPE.HTML5_VPAID];
      return types.indexOf(type) !== -1;
    },

    hasLayout(creative = null) {
      if (creative == null) {
        creative = this.creative;
      }
      return creative.layout != null || creative.layout_id != null;
    },

    hasTemplate(creative = null) {
      if (creative == null) {
        creative = this.creative;
      }
      return creative.template != null || creative.template_id != null;
    },

    hasLayoutLoaded(creative = null) {
      if (creative == null) {
        creative = this.creative;
      }
      return creative.layout != null;
    },

    hasTemplateLoaded(creative = null) {
      if (creative == null) {
        creative = this.creative;
      }
      return creative.template != null;
    },

    hasLORT(creative = null) {
      if (creative == null) {
        creative = this.creative;
      }
      return this.hasLayout(creative) || this.hasTemplate(creative);
    },

    hasLORTLoaded(creative = null) {
      if (creative == null) {
        creative = this.creative;
      }
      return this.hasLayoutLoaded(creative) || this.hasTemplateLoaded(creative);
    },

    getLORTValue(key = null, creative = null) {
      if (key == null) {
        return null;
      }
      if (creative == null) {
        creative = this.creative;
      }

      if (this.hasLayout(creative)) {
        return creative.layout[key];
      } else if (this.hasTemplate(creative)) {
        return creative.template[key];
      }
      return null;
    },

    isCMCreative(custom) {
      if (custom == null) {
        custom = this.creative.custom;
      }
      return custom === CREATIVE_CORE.TEMPLATE;
    },

    isDevtoolCreative(custom) {
      if (custom == null) {
        custom = this.creative.custom;
      }
      return custom === CREATIVE_CORE.DEVTOOL;
    },

    isDraft(status) {
      if (status == null) {
        status = this.creative.status;
      }
      return StatusTraits.methods.isDraft(status);
    },
    isPaused(status) {
      if (status == null) {
        status = this.creative.status;
      }
      return StatusTraits.methods.isPaused(status);
    },
    isLive(status) {
      if (status == null) {
        status = this.creative.status;
      }
      return StatusTraits.methods.isLive(status);
    },
    isWaiting(status) {
      if (status == null) {
        status = this.creative.status;
      }
      return StatusTraits.methods.isWaiting(status);
    },
    isFinished(status) {
      if (status == null) {
        status = this.creative.status;
      }
      return StatusTraits.methods.isFinished(status);
    },
    isDemoFinished(status) {
      if (status == null) {
        status = this.creative.status;
      }
      return StatusTraits.methods.isDemoFinished(status);
    },
    isArchived(status) {
      if (status == null) {
        status = this.creative.status;
      }
      return StatusTraits.methods.isArchived(status);
    },
    isDuplicationInProgress(status) {
      if (status == null) {
        status = this.creative.status;
      }
      return StatusTraits.methods.isDuplicationInProgress(status);
    },
    isImportInProgress(status) {
      if (status == null) {
        status = this.creative.status;
      }
      return StatusTraits.methods.isImportInProgress(status);
    },
    isProcessing(status) {
      if (status == null) {
        status = this.creative.status;
      }
      return StatusTraits.methods.isProcessing(status);
    },
    isDeprecatedLayout(creative) {
      if (creative == null) {
        creative = this.creative;
      }
      const layout = creative.template ?? creative.layout ?? null;
      return layout?.active === -1;
    },
  },
};
</script>
