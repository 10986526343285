export default class FlightMacros {
  #macros = new Map();

  create(statements) {
    this.#macros.clear();
    this.#loopStatements(statements);
  }

  add(condition_id, macro_id) {
    if (this.#macros.has(condition_id)) {
      return;
    }
    this.#macros.set(condition_id, macro_id);
  }

  remove(condition_id) {
    this.#macros.delete(condition_id);
  }

  get() {
    return Array.from(new Set(this.#macros.values()));
  }

  #loopStatements(statements = []) {
    for (const statement of statements) {
      if (statement.statements.length > 0) {
        this.#loopStatements(statement.statements);
      }

      if (!statement.conditions?.length) {
        continue;
      }

      for (const condition of statement.conditions) {
        if (condition.macro_id != null) {
          this.add(condition.id, condition.macro_id);
        }
      }
    }
  }
}
