<template lang="pug">
div#pp-wrapper
  div#profile-wrapper(v-content)

    Loading(v-if="user == null || group == null")

    div.flex.flex-direction-column.row-gap-32(v-else)

      ProfileCard
        template(v-slot:image)
          Illustration(img="billing-icon.svg" :fill="true")

        section.flex.flex-direction-column.row-gap-16
          section.flex.flex-align-center.flex-justify-between
            h2.fs-24.fw-500 Subscription information

            Button(
              v-if="billing_info != null && subscription.canChange()"
              type="primary"
              :label="change_plan_buttom_label"
              :animate="true"
              @click="openPlanEditorModal")
              template(v-slot:prefix)
                IconEdit

          section.flex.flex-direction-column.row-gap-16
            Loading(v-if="billing_info == null")

            template(v-else-if="subscription.noPlan()")
                p.color-gray-800 You have no&nbsp;
                  span.color-primary active&nbsp;
                  | subscription.
                p.color-gray-800 Choose a plan to unlock full platform features.

                PlanSelection(:group="group" :subscription="subscription" @change="planChangeHandler")

            template(v-else-if="subscription.isTrial()")
              p.color-gray-800 You are currently on&nbsp;
                span.color-primary Trial&nbsp;
                | plan
              p.color-gray-800 Choose a plan to unlock full platform features.
              PlanSelection(:group="group" :subscription="subscription" @change="planChangeHandler")

            template(v-else-if="billing_info.stats != null")
              p.color-gray-800 Your current active plan is&nbsp;
                span.color-primary {{ subscription.name }}

                template(v-if="billing_info.stats.plan_usage != null && !subscription.isUnlimited()") &nbsp;of which you are using&nbsp;
                  span.color-primary {{ billing_info.stats.plan_usage | PercentageFilter }}

              div.row(v-if="billing_info.features != null && !subscription.isUnlimited()")
                div.col-6
                  PlanStatsCard(label="Impressions per month" :value="$options.filters.NumberFilter(billing_info.stats.impressions.value)")
                    template(v-slot:chart)
                      PieChart(
                        :class="{infinity: billing_info.features.impressions_per_month === 'unlimited'}"
                        :percentage="billing_info.stats.impressions.percentage"
                      )
                    template(v-slot:footer)
                      div.color-primary.fs-12
                        template(v-if="billing_info.features.impressions_per_month === 'unlimited'") of unlimited
                        template(v-else) of {{ billing_info.features.impressions_per_month | NumberFilter }}
                div.col-6
                  PlanStatsCard(label="Active users" :value="`${$options.filters.NumberFilter(billing_info.stats.users.value)} users`")
                div.col-6
                  PlanStatsCard(label="Live campaigns" :value="`${$options.filters.NumberFilter(billing_info.stats.live_campaigns.value)} live`")
                div.col-6
                  PlanStatsCard(label="Live creatives in campaigns" :value="`${$options.filters.NumberFilter(billing_info.stats.live_creatives.value)} total`")

        section.flex.flex-direction-column.row-gap-16(v-if="!subscription.isTrial() && !subscription.noPlan()")

          h2.fs-24.fw-500 Billing

          template(v-if="invoices != null")
            section(v-if="invoices.outstanding != null")

              p.invoice-block-title Outstanding invoice

              div.invoice-table
                div.form-group
                  InvoiceRow(:invoice="invoices.outstanding")

            section(v-if="invoices.next != null && !no_recurring_payment_plan")
              p.invoice-block-title
                span(v-if="invoices.next.due > 0") Next invoice in&nbsp;
                  span.color-primary {{ invoices.next.due }} {{ invoices.next.due > 1 ? 'days' : 'day' }}

                span(v-else) Next invoice overdue&nbsp;
                  span.color-primary {{ -invoices.next.due }} {{ -invoices.next.due > 1 ? 'days' : 'day' }}

              div.invoice-table
                div.form-group
                  InvoiceRow(:invoice="invoices.next")

            section(v-if="invoices.unpaid != null")
              p.invoice-block-title Unpaid invoices
              template(v-if="invoices.unpaid.length > 0")
                div.invoice-table(:class="invoice_table_class.unpaid" @click="invoice_table_class.unpaid = ''")
                  div.form-group(v-for="invoice in invoices.unpaid" :key="invoice.number")
                    InvoiceRow(:invoice="invoice")
              template(v-else)
                div No upcoming invoices

            section(v-if="invoices.paid != null")
              p.invoice-block-title Paid invoices
              template(v-if="invoices.paid.length > 0")
                div.invoice-table(:class="invoice_table_class.paid" @click="invoice_table_class.paid = ''")
                  div.form-group(v-for="invoice in invoices.paid" :key="invoice.number")
                    InvoiceRow(:invoice="invoice")
              template(v-else)
                h4 No invoices found
            section(v-else)
              p We have not sent you any invoices.
          Loading(v-else)

        section.flex.flex-direction-column.row-gap-16
          h2.fs-24.fw-500 Payment Method
          p(v-if="!group.cards.length && subscription.isEnterprise()")
            | Currently you are billed using bank transfer, if you want to use credit card for billing, please add a payment method.
          CreditCard(:organization="group")

  PlanEditorModal(v-if="show_plan_editor === true && billing_info != null && invoices != null"
    :key="billing_info.plan_id"
    :subscription="subscription"
    :group="group"
    :invoice="invoices.next"
    @change="planChangeHandler"
    @close="closePlanEditorModal"
  )

  ChangeGroupDetailsModal(
    v-if="show_group_details"
    :group="group"
    @close="closeGroupDetails"
  )

  LoadingModal(v-if="making_plan_change_payment" body="Creating a payment, please wait")
</template>

<script>
import CreditCard from '@cm/Views/Billing/Components/CreditCard/CreditCard.vue';
import InvoiceRow from '@cm/Views/Profile/Blocks/Components/InvoiceRow.vue';
import Overview from '@cm/Views/Billing/Views/Overview.vue';
import PieChart from '@cm/Views/Profile/Blocks/Components/PieChart.vue';
import PlanEditorModal from '@cm/Views/Profile/Modals/PlanEditorModal.vue';
import PlanStatsCard from '@cm/Views/Profile/Blocks/Components/PlanStatsCard.vue';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import Loading from '@master/UI/Loading.vue';
import Illustration from '@master/UI/UserImage/Illustration.vue';

import Payment from '@master/Services/PaymentService';
import PermissionMixin from '@master/Services/mixins/PermissionMixin.vue';
import RequestCache from '@libs/RequestCache';
import IconEdit from '@master/UI/Buttons/Icons/IconEdit.vue';
import ProfileCard from '@cm/Views/Profile/Blocks/Components/ProfileCard/ProfileCard.vue';
import PlanSelection from '@cm/Views/Profile/Blocks//Components/PlanSelection.vue';

import LoadingModal from '@master/UI/LoadingModal.vue';
import ChangeGroupDetailsModal from '@cm/Views/Profile/Modals/ChangeGroupDetailsModal.vue';

import GroupSubscription from '@master/Classes/GroupSubscription';
import GroupBillingInfo from '@master/Classes/GroupBillingInfo';

import { PUBLISH_ERROR, VIEW } from '@master/constants';

export default {
  name: 'Billing',
  mixins: [PermissionMixin],

  components: {
    Button,
    Buttons,
    CreditCard,
    InvoiceRow,
    Loading,
    Overview,
    PieChart,
    PlanEditorModal,
    PlanStatsCard,
    Illustration,
    IconEdit,
    ProfileCard,
    PlanSelection,
    LoadingModal,
    ChangeGroupDetailsModal,
  },

  data() {
    return {
      group: null,
      billing_info: null,
      group_billing_info: null,
      subscription: new GroupSubscription(),
      show_group_details: false,
      user: null,
      invoices: null,
      current_balance: null,
      show_plan_editor: false,
      invoice_table_class: {
        paid: 'hidden',
        unpaid: 'hidden',
      },
      group_base_loaded: false,

      making_plan_change_payment: false,
    };
  },

  computed: {
    no_recurring_payment_plan() {
      return this.subscription.isIntegration() || this.subscription.isPayAsYouGo();
    },

    change_plan_buttom_label() {
      if (this.subscription.noPlan()) {
        return 'CHOOSE PLAN';
      }
      return 'VIEW AND EDIT PLAN';
    },
  },

  created() {
    this.$user.subscribe(user => {
      this.user = user;
      if (this.user != null) {
        // validate if user is allowed to see this
        this.fetchGroupDetails();
      }
    }, this);
  },

  methods: {
    fetchGroupDetails() {
      if (!this.can_read_group_billing) {
        this.$router.push({ name: VIEW.PROFILE });
        return;
      }

      let group_id = this.$user.getGroupId();

      // allow nexd admins to open billing with another group details
      if (this.$route.params?.group_id && this.$user.isNexdAdmin()) {
        group_id = this.$route.params?.group_id;
      }

      RequestCache.get(`groups/${group_id}`)
        .then(group => {
          this.group = group;
          this.group_billing_info = new GroupBillingInfo(this.group.billing_info);

          // load extra info only once + only for non dynamic sub users
          if (!this.group_base_loaded && this.group.dynamic_subscription_id == null) {
            this.fetchGroupSubscriptions();
            this.fetchGroupInvoices();
            this.fetchGroupBalance();
          }
          this.group_base_loaded = true;
        })
        .catch(_ => {
          // probably user has no permissions to come here, redirect to profile page
          this.$router.push({ name: VIEW.PROFILE });
        });
    },

    fetchGroupSubscriptions() {
      const path = `groups/${this.group.group_id}/subscription`;
      this.$http
        .get(path)
        .then(response => {
          this.billing_info = response;
          this.subscription = new GroupSubscription(this.billing_info.subscription);
        })
        .finally(_ => {
          // after data is reloaded, remove the loading
          this.making_plan_change_payment = false;
        });
    },

    fetchGroupInvoices() {
      const path = `groups/${this.group.group_id}/invoices`;
      this.$http
        .get(path, { notification: false })
        .then(response => {
          this.invoices = response;
        })
        .catch(() => {
          this.invoices = false;
        });
    },

    fetchGroupBalance() {
      const path = `groups/${this.group.group_id}/balance/current`;
      this.$http
        .get(path, { notification: false })
        .then(response => {
          this.current_balance = response;
        })
        .catch(() => {
          this.current_balance = false;
        });
    },

    openPlanEditorModal() {
      this.validatePlanChange()
        .then(() => {
          this.show_plan_editor = true;
        })
        .catch(() => {
          /** errors handled in the validator */
        });
    },

    closePlanEditorModal() {
      this.show_plan_editor = false;
    },

    validatePlanChange() {
      return new Promise((resolve, reject) => {
        this.$http
          .get('canpublish')
          .then(resolve)
          .catch(async error => {
            // only on one error, if one creative is selected
            const { code } = error.response.result;

            if (code === PUBLISH_ERROR.BILLING_INFO) {
              const result = await this.$confirm('Invalid billing address', 'Please fill in all required information about your organization', {
                buttons: [
                  { type: 'link-primary', label: 'Cancel', action: null },
                  { type: 'primary', label: 'Open', action: true },
                ],
              });
              this.show_group_details = result;
              return reject(code);
            }

            if (code === PUBLISH_ERROR.MISSING_CC) {
              const result = await this.$confirm('Missing credit card', 'Before changing or activating a plan, credit card must be added', {
                buttons: [
                  { type: 'link-primary', label: 'Cancel', action: null },
                  { type: 'primary', label: 'Add Card', action: true },
                ],
              });
              if (result) {
                const payment = new Payment();
                payment.addCard();
              }
              return reject(code);
            }

            resolve();
          });
      });
    },

    async planChangeHandler(selected_plan_id) {
      // if request from modal, close that beforehand
      this.closePlanEditorModal();

      this.validatePlanChange()
        .then(() => {
          const path = `group/${this.group.group_id}/subscription/upgrade`;

          this.$http.get(`${path}?plan_id=${selected_plan_id}`, { notification: false }).then(async response => {
            let heading, body;
            if (response.upgrade) {
              heading = 'Confirm upgrade';
              body = `This action will upgrade your subscription to <strong>${response.plan.name}</strong>.
                <br><br>Any recurring payments will be with the new price on the <strong>1st of every month</strong>.`;
            } else {
              heading = 'Confirm payment';
              body = `By confirming, you will initiate a payment of <strong>${response.total_price} ${response.currency}</strong><br>
                This will activate <strong>${response.plan.name}</strong> subscription plan from <strong>${response.date.from}</strong> to <strong>${response.date.to}</strong>.
                <br><br>Any recurring payments will be processed on the <strong>1st of every month</strong>.`;
            }

            const result = await this.$confirm(heading, body, {
              buttons: [
                { type: 'link-primary', label: 'Cancel', action: null },
                { type: 'primary', label: 'Activate', action: true },
              ],
            });

            if (!result) {
              return;
            }

            this.making_plan_change_payment = true;
            this.$http
              .post(path, { plan_id: selected_plan_id }, { notification: false })
              .catch(e => {
                this.making_plan_change_payment = false;
                this.$alert(e.message);
              })
              .finally(() => {
                this.fetchGroupSubscriptions();
                this.fetchGroupInvoices();
                this.fetchGroupBalance();
              });
          });
        })
        .catch(_ => {
          /** errors handled in the validator */
        });
    },

    closeGroupDetails() {
      this.group_billing_info = new GroupBillingInfo(this.group.billing_info);
      this.show_group_details = false;
    },

    dynamicPricingPurchased() {
      window.location.reload();
    },
  },
};
</script>
