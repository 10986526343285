<template lang="pug">
div.modal.modal-md.plan-editor-modal
  div.backdrop
  div.modal-content
    div.row
      div.col-8
        div.modal-head
          div.modal-title Subscription Plan
        div.modal-body

          div.mb-16
            label.mb-4
              template(v-if="subscription.plan_id === selected_plan_id")
                span Your currently active
                | <template v-if="subscription.trial">&nbsp;trial</template>
                | plan is
              span(v-else) Your new plan will be

            SearchSelect(
              v-if="plans != null"
              :options="plans"
              v-model="selected_plan_id"
              :search="false"
            )
            Loading( v-else)

          Buttons(align="left")
            Button(type="success" :label="can_activate_selection ? 'Upgrade' : 'Activated'" :disabled="!can_activate_selection" :animate="true" @click="choosePlan")

          a.fs-14.mt-16( href="https://www.nexd.com/pricing/" target="_blank") Compare plans in detail

          ul(v-if="selected_plan?.features")
            li(v-for="feature of selected_plan.features" :key="feature.feature_id")
              span.fs-12 {{feature.name}}
                span(v-if="!isNaN(parseInt(feature.value))")  - {{parseInt(feature.value) | NumberFilter}}
                span(v-else-if="feature.value !== 'true'")  - {{feature.value}}

          // disabled because currently we dont actually bill on plan change
          template(v-if="subscription.noPlan()")
            div.plan-inline-notification.mt-16
              div.notification-icon !
              div.notification-content
                | The billing period begins on the day the subscription is activated. The current payment for this activation covers only the period from the activation date until the 1st day of the next month.

        Buttons.modal-footer(align="left")
          Button(type="link-primary" label="Back" @click="$emit('close')")
      div.right-side.col-16.flex.flex-align-center
        div.flex-grow.text-center
          img( :src="$cdn + 'dist/assets/cm/subscription-modal-icon.svg'" alt="subscription")
          template(v-if="invoice != null")
            h1 {{invoice.due}} days
            p Next payment will be reserved
              br
              | from your card on {{invoice.date | DateFilter}}
</template>

<script>

import CreditCard from '@cm/Views/Billing/Components/CreditCard/CreditCard.vue';

import SearchSelect from '@master/UI/SearchSelect/SearchSelect.vue';
import Loading from '@master/UI/Loading.vue';
import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';

import { SUBSCRIPTION } from '@master/constants';

import Modal from '@master/UI/Modal.vue';

export default {
  name: 'PlanEditorModal',
  extends: Modal,

  components: {
    CreditCard,
    SearchSelect,
    Loading,
    Button,
    Buttons
  },

  props: {
    group: Object,
    subscription: Object,
    invoice: Object
  },

  computed: {
    active_plan_index() {
      if (!this.plans) return 0;
      return this.plans.findIndex(plan => (plan.value === this.subscription.plan_id));
    },

    selected_plan_index() {
      if (!this.plans) return 0;
      return this.plans.findIndex(plan => (plan.value === this.selected_plan_id));
    },

    can_activate_selection() {
      return this.active_plan_index < this.selected_plan_index;
    },

    selected_plan() {
      return this.plans?.find(plan => plan.value === this.selected_plan_id) ?? null;
    }
  },

  data() {
    return {
      selected_plan_id: this.subscription.plan_id ?? SUBSCRIPTION.BASIC,
      plans: null,
    };
  },

  created() {
    const currency = this.$user.getCurrency();
    const path = `public/subscriptions?currency=${currency}`;

    this.$http.get(path)
      .then(response => {
        this.plans = [];

        for (const plan of response) {

          // exclude non subscription lans
          if ([SUBSCRIPTION.ENTERPRISE, SUBSCRIPTION.INTEGRATION, SUBSCRIPTION.PAYASYOUGO].includes(plan.plan_id)) continue;

          // object for search select component
          this.plans.push({
            value: plan.plan_id,
            label: plan.name,
            description: plan.description,
            features: plan.features.filter(feature => {
              return feature.value !== 'false';
            })
          });
        }
      });
  },

  methods: {
    choosePlan() {
      if (!this.can_activate_selection) return;
      this.$emit('change', this.selected_plan_id);
    },
  }
};
</script>
