<script>
export default {
  computed: {
    instream_expert() {
      return this.$user.isInstreamExpert();
    },

    show_responsive_type_option() {
      return this.admin_toggle || this.is_enterprise_plan || this.has_responsive_feature;
    },

    show_skin_type_options() {
      return this.admin_toggle || this.is_enterprise_plan;
    },
  },

  data() {
    return {
      admin_toggle: false,
      is_enterprise_plan: false,
      has_responsive_feature: false,
    };
  },

  created() {
    this.$user.subscribe(_ => {
      this.is_enterprise_plan = this.$user.getSubscription().isEnterprise();

      this.admin_toggle = this.$user.adminMode();
      this.has_responsive_feature = this.$user.isResponsiveApproved();
    }, this);
  },
};
</script>
