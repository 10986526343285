<template lang="pug">
div.options-block(v-if="is_visible")

  template(v-if="name === 'custom_close' || name === 'custom_close_lightbox'")
    OptionsBlockRow(:label="'Close icon'" :tooltip="close_icon_tooltip")
      CustomAsset(:asset_id="name" :creative="creative" :auto_crop_size="auto_crop_size" @change="customAssetChangeHandler")

  template(v-else-if="name === 'custom_gesture_lightbox'")
    OptionsBlockRow(label="Gesture" tooltip="You can enable or disable the lightbox gesture. The gesture helps to indicate that the ad can be interacted with.")
      Toggle(:value="creative.settings.custom_gesture_lightbox ?? false" @input="customSettingChangeHandler($event, 'custom_gesture_lightbox')")

    OptionsBlockRow(label="Gesture icon" tooltip="Scale and reposition the existing gesture icon or upload a custom gesture icon")
      CustomAsset(:asset_id="name" :creative="creative" @change="customAssetChangeHandler")

  template(v-else-if="name === 'replay'")
    OptionsBlockRow(:label="item.title")
      div.flex.flex-align-center.column-gap-8
        Thumbnail(:asset="{thumbnail_url: item.original_url}" :small="true")
        i.nexd-icon-32-edit.hover-primary(aria-hidden="true" v-tooltip="{value: 'Edit'}" @click="editAsset")

  template(v-else)
    OptionsBlockRow(:label="item.title" :tooltip="item.tooltip")
      Toggle(:value="creative.settings[name] ?? item.default ?? false" @input="customSettingChangeHandler($event, name)")
</template>

<script>
import Thumbnail from '@master/UI/Thumbnail/Thumbnail.vue';
import Toggle from '@master/UI/Toggle.vue';

import CustomAsset from './CustomAsset.vue';
import OptionsBlockRow from './OptionsBlockRow.vue';

export default {
  name: 'CustomOptionsBlock',

  components: {
    CustomAsset,
    OptionsBlockRow,
    Thumbnail,
    Toggle,
  },

  props: {
    creative: Object,
    name: String,
    item: Object,
    arno: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    close_icon_tooltip() {
      if (this.name === 'custom_close_lightbox') {
        return `You can upload a custom close icon. Bear in mind, that the close icon should be in 1:1 (square) dimensions,
          otherwise our system will crop the image to square itself. The minimum recommended size is 60x60 px`;
      }
      return 'Upload or drag a custom close button icon from Asset library';
    },

    is_visible() {
      return this.item != null && (this.admin_toggle || this.item.visibility === 'all' || (this.item.visibility === 'feature' && this.$user.hasGroupFeature(this.name)));
    },

    auto_crop_size() {
      if (this.name === 'custom_close_lightbox') {
        return 64;
      }
      return null;
    },
  },

  data() {
    return {
      admin_toggle: false,
      Cropper: this.$cropper(),
    };
  },

  created() {
    this.$user.subscribe(() => {
      this.admin_toggle = this.$user.adminMode();
    }, this);
  },

  methods: {
    customAssetChangeHandler(reload_assets_library = false) {
      this.$emit('change', reload_assets_library);
    },

    customSettingChangeHandler(value, key) {
      this.$set(this.creative.settings, key, value);

      this.$http.put(`creative/${this.creative.creative_id}/settings/${key}`, { value }).then(() => {
        this.$emit('change');
        this.$forceUpdate();
      });
    },

    editAsset() {
      if (this.item.asset_id == null) {
        return;
      }

      this.Cropper.init({
        cropSize: {
          width: this.creative.width,
          height: this.creative.height,
        },
        position: 'absolute',
        clip: {
          horizontal: true,
          vertical: true,
        },
      });

      this.Cropper.onsave = settings => {
        this.$http.put(`creatives/${this.creative.creative_id}/assets/${this.item.asset_id}`, { crop: false, settings }).then(asset => {
          for (const key in this.item) {
            if (key in asset) {
              this.$set(this.item, key, asset[key]);
            }
          }
        });
      };

      this.Cropper.editAsset(this.item.original_url, this.item.settings);
    },
  },
};
</script>
