<template lang="pug">
ImportCard(:active="true" :replace="replace")
  template(v-if="!replace" v-slot:title) Import HTML5 Banner

  section
    p.color-gray-800.fs-14.mb-16(v-if="!replace") Upload your existing HTML5 banner(s), and we will optimize the assets and the code so it loads faster.
      template(v-if="!is_sdk_user")
        | After publishing you can see basic analytics (impressions, clicks, engagement) in campaign analytics.
        | Read more about&nbsp;
        a(href="http://support.nexd.com/en/articles/5529699-import-html5-banners" target="_blanck") HTML5 support in Nexd Campaign Manager.

    template(v-if="lightbox && creative")
      CreativeRow.stretch(label="Website URL:" :tooltip="{value: 'Ensure the webpage URL allows embedding via iframe (check x-frame-options settings). If not permitted, the ad will not display correctly.'}")
        template(v-slot:content)
          form.row.flex-grow(@submit.prevent="html5SettingChangeHandler")
            div.col.flex-grow
              Input(variant="url" v-model="third_party_url" placeholder="Insert URL" :validate="false" @blur="html5StettingInputChange")
            div.col
            Button(type="primary" label="Save" :loading="saving" :disabled="!url_set_enabled" :submit="true")


      Divider.my-16(text="OR")

    div.mb-8(v-if="!is_sdk_user")
      Button(type="link" label="Read HTML5 file requirements" :href="requirements_link")
    ImportDropArea(:textarea="false" :mime="['application/zip']" @change="fileChangeHandler")

  LoadingModal(
    v-if="loading_percentage != null"
    head="Importing HTML5 banners"
    body="Please wait while files are uploaded and optimized for better performance"
    :percentage="loading_percentage"
  )

  template(v-slot:buttons)
    Button(v-if="can_close" type="link-primary" label="Cancel" @click="cancel")
</template>

<script>
import { newCreative } from '@helpers/Creative';
import { showAssetUploadErrors } from '@helpers/Alerts';
import { validateURL } from '@helpers/Global';
import Upload from '@helpers/Upload';

import { DEVICE, TYPE } from '@master/constants';

import CreativeRow from '@cm/Views/Creatives/Creative/CreativeRow.vue';
import ImportCard from '@cm/Views/Creatives/Cards/Import/ImportCard.vue';
import ImportDropArea from '@cm/Views/Creatives/Cards/Import/ImportDropArea.vue';

import Divider from '@master/UI/Divider/Divider.vue';
import Input from '@master/UI/Input/Input.vue';
import Button from '@master/UI/Buttons/Button.vue';
import LoadingModal from '@master/UI/LoadingModal.vue';
import Toggle from '@master/UI/Toggle.vue';

import CreativeTraits from '@master/Traits/CreativeTraits.vue';
import CreativeMixins from '@cm/Views/Creatives/Creative/CreativeMixins.vue';
import SDKMixin from '@root/src/global/mixins/SDKMixin.vue';

export default {
  name: 'HTML5Import',
  mixins: [CreativeTraits, CreativeMixins, SDKMixin],

  components: {
    Divider,
    Input,
    Button,
    CreativeRow,
    ImportCard,
    ImportDropArea,
    LoadingModal,
    Toggle,
  },

  props: {
    replace: {
      type: Boolean,
      default: false,
    },
    creative: {
      type: Object,
      default: () => null,
    },
    lightbox: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    url_set_enabled() {
      return this.third_party_url !== '' && !this.saving;
    },

    can_close() {
      if (this.is_new) return true;
      return this.creative?.settings?.html5?.third_party_url || this.creative?.imported;
    },

    requirements_link() {
      if (this.lightbox) {
        return 'https://support.nexd.com/en/articles/8486183-lightbox-ad-type';
      }

      return 'https://support.nexd.com/en/articles/5529699-import-html5-banners';
    },
  },

  data() {
    return {
      TYPE,
      component_creative: null,
      loading_percentage: null,
      third_party_url: '',
      saving: false,
    };
  },

  async created() {
    if (this.creative != null) {
      // existing creative and/or lightbox always have 2nd state
      this.component_creative = this.creative;
    } else {
      // new import
      this.component_creative = await newCreative({
        type: TYPE.HTML5_INFEED,
        device: DEVICE.HTML5,
        imported: true,
      });
    }
  },

  methods: {
    html5StettingInputChange() {
      this.third_party_url = validateURL(this.third_party_url);
    },

    html5SettingChangeHandler() {
      if (this.saving || this.creative?.settings?.html5 == null) return;

      this.saving = true;
      this.creative.settings.html5.third_party_url = this.third_party_url;

      const path = `creative/${this.creative.creative_id}/settings/html5`;
      this.$http
        .put(path, { value: this.creative.settings.html5 })
        .then(response => {
          this.$set(this.creative, 'imported', false);
          this.$set(this.creative, 'imported_zip', null);
          this.$set(this.creative.settings, 'html5', response.html5);
          this.$emit('onClose');
        })
        .finally(_ => {
          this.saving = false;
        });
    },

    async fileChangeHandler(event) {
      const { files, errors } = event;

      if (errors?.length > 0) {
        await showAssetUploadErrors(errors);
      }

      this.importFiles(files);
    },

    async cancel() {
      let msg = 'All changes will be discarded';
      if (!this.component_creative.creative_id.startsWith('new-')) {
        msg = 'This will close the replace drop view and return to the creative card view';
      }
      // reset all changes to original creative
      let result = await this.$confirm('Are you sure?', msg);
      if (result) {
        this.$emit('onClose');
      }
    },

    importFiles(files = null) {
      if (!files || files.length === 0) {
        return;
      }

      this.loading_percentage = 0;
      const file_uploads = [];
      let loadings = [];

      for (const file of files) {
        let index = loadings.push(0) - 1;
        file_uploads.push(
          new Promise((resolve, reject) => {
            const handler = new Upload(file);
            handler.upload(({ percentage, done, error, info, upload_id }) => {
              loadings[index] = percentage;
              this.loading_percentage = Math.min(loadings.reduce((a, b) => a + b, 0) / loadings.length, 0.99);

              if (error?.message != null) {
                return reject(error.message);
              }

              if (done) {
                let obj = {
                  type: info.extension,
                  upload_id,
                };

                // add creative_id when replacing import
                if (this.replace) {
                  obj.creative_id = this.component_creative.creative_id;
                }

                resolve(obj);
              }
            });
          }),
        );
      }

      Promise.allSettled(file_uploads).then(promises => {
        const obj = {
          campaign_id: this.$route.params.campaign_id ?? this.creative?.campaign_id ?? null,
          folder_id: this.$route.params.folder_id ?? null,
          import: [],
        };

        for (const promise of promises) {
          if (promise.status === 'fulfilled') {
            obj.import.push(promise.value);
          } else if (promise.status === 'rejected') {
            // no error handling in html5
          }
        }

        const path = 'v2/import/async';
        this.$http
          .post(path, obj)
          .then(response => {
            // parent has different callbacks linekd with oncreate
            // on new import it has create handle (which accepts multiple items)
            // on reimport it has reimport handle (which accepts single item)
            if (this.replace) {
              this.$emit('onCreate', response[0]);
              return;
            }
            this.$emit('onCreate', response);
          })
          .catch(async error => {
            // only show errors if they exist
            if (error.errors_list) {
              await showAssetUploadErrors(
                [],
                error.errors_list.map(e => e.msg),
              );
            }
          })
          .finally(_ => {
            this.loading_percentage = null;
          });
      });
    },
  },
};
</script>
