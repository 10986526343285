<template lang="pug">
div
  slot(name="logo")

  Maintenance(v-if="maintenance")

  template(v-else-if="view === LVIEW.EMAIL_CONFIRM")
    ConfirmEmail(@click="openLogin")
  template(v-else-if="view === LVIEW.DEFAULT")

    Loading(v-if="hidden" color="#ffffff")

    form#content-wrapper(:class="{'hidden': hidden}")
      div#title
        h1#magic-title This is where
          br
          | the magic happens
        h5 Let's get creative.
          br
          | Log in and start building.

      div#form
        template(v-if="show_social_login")
          OAuthSignIn
          Divider.fs-14.color-white(type="solid" text="or" style="--border-color: hsla(0, 0%, 100%, .15)")
        iframe(ref="login" :src="auth_iframe_src" frameborder="0" scrolling="no" :height="login_form_height")
</template>

<script>
import Maintenance from '@login/Components/Maintenance.vue';
import ConfirmEmail from '@login/Components/ConfirmEmail.vue';
import OAuthSignIn from '@master/UI/OAuthSignIn.vue';
import Input from '@master/UI/Input/Input.vue';
import Button from '@master/UI/Buttons/Button.vue';
import Divider from '@master/UI/Divider/Divider.vue';
import Loading from '@master/UI/Loading.vue';
import LoginService from '@login/LoginService';

import { VIEW } from '@master/constants';

const LVIEW = {
  DEFAULT: 'login',
  EMAIL_CONFIRM: 'emailconfirm',
};

const LOGIN_BASE_HEIGHT = 320;

export default {
  name: 'Login',

  components: {
    Maintenance,
    ConfirmEmail,
    Input,
    Button,
    OAuthSignIn,
    Divider,
    Loading,
  },

  computed: {
    auth_iframe_src() {
      let redirect = `${this.$auth}login`;
      const params = new URLSearchParams({
        embedded: true,
      });

      let state = 'login';
      if (process.env.NODE_ENV === 'development') {
        // add dev env when needed to make the social login redirect back to :8081
        params.append('env', process.env.NODE_ENV);
        state += `.${process.env.NODE_ENV}`;
      }
      if (this.$route.query.ref) {
        // add ref when exists to override default redirect from social buttons
        params.append('ref', this.$route.query.ref);
        state += `|${this.$route.query.ref}`;
      }

      // add state to properly handle normal login callback redirection
      params.append('state', state);
      redirect += `?${params.toString()}`;
      return redirect;
    },
  },

  data() {
    return {
      LVIEW,

      maintenance: false,
      view: null,
      hidden: true,
      redirect_url: '/home',
      show_social_login: true,
      login_form_height: LOGIN_BASE_HEIGHT,
    };
  },

  created() {
    if (this.$route.query.confirm != null) {
      this.view = LVIEW.EMAIL_CONFIRM;
    } else {
      this.checkIfAccessTokenIsActive()
        .then(status => {
          if (status) {
            if (this.handleRedirectRef()) {
              return;
            }
            // supress auto redirect error
            // happens when cookie request is a success but /me request is not
            // that case would produce inf. loop that will throw a route guard error
            return this.$router.push({ name: VIEW.CAMPAIGNS }).catch(_ => {
              /** supress errors */
            });
          }
        })
        .finally(() => {
          this.view = LVIEW.DEFAULT;
        });
    }

    window.onmessage = messsage => {
      try {
        if (this.$auth.startsWith(messsage.origin)) {
          this.adjustLogin(messsage.data);
          const mock = LoginService.getMockRegistration();
          if (mock) {
            messsage.source.postMessage(mock, event.origin);
          }
        }
      } catch {
        this.adjustLogin(null);
      }
    };
  },

  methods: {
    adjustLogin(data = null) {
      this.login_form_height = LOGIN_BASE_HEIGHT;
      this.show_social_login = true;

      if (data == null) return;

      this.login_form_height = data.height;

      // captcha loads async
      if (data.captcha_required) {
        this.login_form_height += 78;
      }

      if (data.require_mfa) {
        this.show_social_login = false;
      }
    },

    handleRedirectRef() {
      if (this.$route.query.ref == null) {
        return false;
      }

      this.redirect_url = decodeURIComponent(this.$route.query.ref);
      // if the redirect ref is https://something.com (full url) reidrect to that instead of making any other checks
      if (this.redirect_url.match(/https:\/\//g)) {
        this.redirect();

        // return true so any code that called this could stop
        return true;
      }

      return false;
    },

    checkIfAccessTokenIsActive() {
      return new Promise((resolve, reject) => {
        this.$http
          .get('user/cookie')
          .then(response => {
            return resolve(response.logged_in);
          })
          .finally(() => {
            this.hidden = false;
            return reject();
          });
      });
    },

    openLogin() {
      this.view = LVIEW.DEFAULT;
      this.hidden = false;

      // also push same route without query params
      this.$router.push({ name: VIEW.LOGIN }).catch(() => {
        /** supress duplicate route warning */
      });
    },

    redirect() {
      window.location.href = this.redirect_url;
    },
  },
};
</script>
