<template lang="pug">
Modal(size="modal-sm" title="Add new macro" :loading="loading")
  template(v-slot:body)
    div.flex.flex-direction-column.row-gap-24
      label
        span.mb-4.flex.flex-align-center.column-gap-4 Macro name
          i.nexd-icon-16-help(aria-hidden="true" v-tooltip="{value: 'Identifier for macro. Only basic alphanumeric chars can be used.'}")
        input.form-control(v-model="name" placeholder="my_macro")

      label
        span.mb-4.flex.flex-align-center.column-gap-4 Macro value
          i.nexd-icon-16-help(aria-hidden="true" v-tooltip="{value: 'The macro name from DSP - e.g. %%MY_TRG_MACRO%% or {{P1_DATA_M}}'}")
        input.form-control(v-model="value" :placeholder="'{{DSP_MACRO_HERE}}'")

  template(v-slot:footer)
    Buttons
      Button(type="link-primary" label="Close" @click="close")
      Button.text-capitalize(type="primary" label="Save" animate @click="addMacro")
</template>

<script>
import FlightService from '@master/Services/FlightService';

import Button from '@master/UI/Buttons/Button.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import Modal from '@master/Modals/Modal.vue';

export default {
  name: 'FlightAddMacroModal',

  components: {
    Button,
    Buttons,
    Modal,
  },

  data() {
    return {
      flight: null,
      loading: false,
      name: '',
      value: '',
    };
  },

  created() {
    FlightService.subscribe(data => {
      this.flight = data?.flight;
    }, this);
  },

  methods: {
    addMacro() {
      if (this.loading) {
        return;
      }

      this.loading = true;

      this.$http
        .post(`v2/flights/${FlightService.getFlightId()}/macros`, {
          macro_name: this.name,
          macro_value: this.value,
        })
        .then(new_macro => {
          if (this.flight.condition_macros === undefined) {
            this.flight.condition_macros = [];
          }
          this.flight.condition_macros.push(new_macro);

          FlightService.update(this.flight);
          this.close();
        })
        .catch(_ => {
          /** errors handled by the notification service */
        })
        .finally(_ => {
          this.loading = false;
        });
    },

    close() {
      this.$emit('close');
    },
  },
};
</script>
