<template lang="pug">
div#app-wrapper.full-width
  StickyHeader
    div.flex-grow
      div.flex.flex-align-center.flex-justify-between.column-gap-32
        Breadcrumb(:items="breadcrumbs")
        div.flex.flex-align-center.column-gap-32
          label.flex.flex-align-center.mr-24(v-if="is_admin")
            input(type="checkbox" :checked="is_admin_scope" @input="changeScope")
            span.color-cyan-blue Admin
            i.nexd-icon-32-help(aria-hidden="true" v-tooltip="{value: 'Show results from all users and groups.'}")
          div.flex.flex-align-center(v-if="can_read_group_content && !admin_scope_title")
            label.color-cyan-blue Inc. all users
            i.nexd-icon-32-help(aria-hidden="true" v-tooltip="{value: scope_tooltip}")
            Toggle(v-model="scope" :disabled="is_admin_scope" :off="scopes[0]" :on="scopes[1]" @input="handleChanges")
          SearchSelectSteps(
            v-model="active_filter"
            :options="filters"
            :values="active_filter_objects.selected"
            :opts="active_filter_objects.options"
            :search="false"
            filter="Filter"
            @update="activeFilterChangeHandler"
          )
            template(v-slot:icon)
              i.nexd-icon-16-filter.p-8(aria-hidden="true")

          SearchInput(
            v-model="query"
            @input="searchHandler"
          )
      div.mt-16.mb-8.flex.flex-align-center.column-gap-24.fs-14.color-gray-800(v-if="has_active_queries")
        div.flex.flex-align-center.column-gap-4(v-if="admin_scope_title")
          span in
          span.active-filter-value.with-remove-icon(@click="clearId") {{ admin_scope_title }}
        div.flex.flex-align-center.column-gap-4(v-if="has_active_search")
          span Searched by:&nbsp;
          span.active-filter-value.with-remove-icon(@click="clearSearch") {{ query }}
            RemoveIcon(type="string")
        div.flex.flex-align-center.column-gap-4(v-if="has_active_filters")
          span Filtered by:&nbsp;
          template(v-for="(filters, filter_type) in active_filters")
            template(v-if="Object.keys(filters).length > 0" v-for="(filter, key) in filters")
              span.active-filter-value.with-remove-icon(:class="{'text-capitalize': !['keywords', 'advertisers'].includes(filter_type)}" @click="clearFilter(filters, filter, filter_type)") {{ filter.label }}
                RemoveIcon(type="string")
        div.flex-grow.flex.flex-justify-end
          span.color-red-primary.cursor-pointer(@click="clearAllFilters") Clear all

  div#search-list(v-content)
    Loading(v-if="loaded == null")
    NoMatchedItemsList(v-else-if="no_search_results" @clear="clearAllFilters")
    template(v-else)

      template(v-if="!hide_folders")
        h4.mb-8 Folders
        template(v-if="have_folders")
          ResultItem(
            v-for="folder of items.folders"
            :key="folder.folder_id"
            :item="folder"
          )
            template(v-slot:thumbnail)
              FolderThumbnail

          Buttons.mt-8(align="left")
            Button(
              v-if="can_load.folders"
              type="link"
              :disabled="loading"
              :label="`Load more (${(meta?.folders?.total ?? 0) - offset.folders})`"
              @click="loadMoreFolders"
            )
        template(v-else)
          small.color-gray-800 no results found

      template(v-if="!hide_campaigns")
        h4.mb-8.mt-24 Campaigns
        template(v-if="have_campaigns")
          ResultItem(
            v-for="campaign of items.campaigns"
            :key="campaign.campaign_id"
            :item="campaign"
          )
          Buttons.mt-8(align="left")
            Button(
              v-if="can_load.campaigns"
              type="link"
              :disabled="loading"
              :label="`Load more (${(meta?.campaigns?.total ?? 0) - offset.campaigns})`"
              @click="loadMoreCampaigns"
            )
        template(v-else)
          small.color-gray-800 no results found

      template(v-if="!hide_creatives")
        h4.mb-8.mt-24 Creatives
        template(v-if="have_creatives")
          ResultItem(
            v-for="creative of items.creatives"
            :key="creative.creative_id"
            :item="creative"
          )
            template(v-slot:thumbnail)
              CreativeThumbnail(:asset="creative")
          Buttons.mt-8(align="left")
            Button(
              v-if="can_load.creatives"
              type="link"
              :disabled="loading"
              :label="`Load more (${(meta?.creatives?.total ?? 0) - offset.creatives})`"
              @click="loadMoreCreatives"
            )
        template(v-else)
          small.color-gray-800 no results found

    UpsellingFooter.text-center(type="search")

  EditorSidebar
</template>

<script>
import Breadcrumb from '@cm/UI/Global/Breadcrumb.vue';
import NoMatchedItemsList from '@cm/UI/Global/NoMatchedItemsList.vue';
import ResultItem from '@cm/Views/Search/ResultItem.vue';
import CreativeThumbnail from '@master/UI/Thumbnail/CreativeThumbnail.vue';
import FolderThumbnail from '@master/UI/Thumbnail/FolderThumbnail.vue';
import EditorSidebar from '@root/src/global/sidebar/edit/EditorSidebar.vue';

import UpsellingFooter from '@master/UI/UpsellingFooter.vue';
import Buttons from '@master/UI/Buttons/Buttons.vue';
import Button from '@master/UI/Buttons/Button.vue';
import SearchInput from '@master/UI/Input/SearchInput.vue';
import SearchSelectSteps from '@master/UI/SearchSelect/SearchSelectSteps.vue';
import RemoveIcon from '@master/UI/RemoveIcon.vue';
import Loading from '@master/UI/Loading.vue';
import Toggle from '@master/UI/Toggle.vue';
import StickyHeader from '@master/UI/StickyHeader/StickyHeader.vue';

import FilterService from '@master/Services/FilterService';
import UsersService from '@master/Services/Cache/UsersService';
import SearchService from '@master/Services/SearchService';

import PermissionMixin from '@master/Services/mixins/PermissionMixin.vue';

import { BREADCRUMB, SEARCH, VIEW, LISTITEM } from '@master/constants';
import { addFiltersToPath } from '@helpers/Global';
import FilterSelection from '@libs/FilterSelection';

const LIMIT = 5;

export default {
  name: 'SearchView',
  mixins: [PermissionMixin],

  components: {
    Breadcrumb,
    NoMatchedItemsList,
    UpsellingFooter,
    Buttons,
    Button,
    SearchInput,
    SearchSelectSteps,
    RemoveIcon,
    Loading,
    Toggle,
    ResultItem,
    CreativeThumbnail,
    FolderThumbnail,
    EditorSidebar,
    StickyHeader,
  },

  computed: {
    no_search_results() {
      return this.loaded != null && !this.have_folders && !this.have_campaigns && !this.have_creatives;
    },

    hide_folders() {
      return this.loaded != null && this.items.folders == null;
    },

    hide_campaigns() {
      return this.loaded != null && this.items.campaigns == null;
    },

    hide_creatives() {
      return this.loaded != null && this.items.creatives == null;
    },

    have_folders() {
      return !this.hide_folders && this.items?.folders?.length > 0;
    },

    have_campaigns() {
      return !this.hide_campaigns && this.items?.campaigns?.length > 0;
    },

    have_creatives() {
      return !this.hide_creatives && this.items?.creatives?.length > 0;
    },

    active_filter_objects() {
      switch (this.active_filter) {
        case 'layouts':
          return {
            options: this.layout_options,
            selected: this.filter_selection.layouts,
          };
        case 'status':
          return {
            options: this.status_options,
            selected: this.filter_selection.status,
          };
        case 'types':
          return {
            options: this.type_options,
            selected: this.filter_selection.types,
          };
        case 'keywords':
          return {
            options: this.keyword_options,
            selected: this.filter_selection.keywords,
          };
        case 'advertisers':
          return {
            options: this.advertiser_options,
            selected: this.filter_selection.advertisers,
          };
        default:
          return {
            options: [],
            selected: {},
          };
      }
    },

    active_filters() {
      const filters = {
        layouts: this.filter_selection.layouts,
        status: this.filter_selection.status,
        types: this.filter_selection.types,
        keywords: this.filter_selection.keywords,
        advertisers: this.filter_selection.advertisers,
      };

      for (const filter_type in filters) {
        for (const filter_key in filters[filter_type]) {
          if (filter_key == null || filter_key === 'null') {
            delete filters[filter_type][filter_key];
            break;
          }
        }
      }

      return filters;
    },

    has_active_filters() {
      for (const filter_type in this.active_filters) {
        if (Object.keys(this.active_filters[filter_type]).length > 0) {
          return true;
        }
      }

      return false;
    },

    has_active_search() {
      return this.query !== '';
    },

    has_active_queries() {
      return this.has_active_filters || this.has_active_search;
    },

    breadcrumbs() {
      return [
        {
          active: true,
          type: BREADCRUMB.ROOT,
          url: null,
          label: 'Search results:',
        },
      ];
    },

    admin_scope_title() {
      if (!this.id || !this.users) return null;

      if (this.id.startsWith('g_')) {
        const id = this.id.replace('g_', '');
        return this.users.find(u => u?.group?.group_id === id)?.group?.name;
      }

      return this.users.find(u => u?.user_id === this.id)?.name;
    },

    filters() {
      return [
        { value: 'layouts', label: 'Layout' },
        { value: 'status', label: 'Status' },
        { value: 'types', label: 'Ad Type' },
        {
          value: 'keywords',
          label: 'Keyword',
          disabled: !this.keyword_options?.length,
        },
        {
          value: 'advertisers',
          label: 'Advertiser',
          disabled: !this.advertiser_options?.length,
        },
      ];
    },

    scope_tooltip() {
      if (this.is_admin_scope) {
        return 'Uncheck the admin checkbox in order to see only group or personal results.';
      }

      return 'As an organization admin you can search any items created by your organization members.';
    },

    is_admin_scope() {
      return this.scope === SEARCH.ADMIN;
    },
  },

  data() {
    return {
      SEARCH,

      is_admin: false,
      loading: false,

      // selected filters
      filter_selection: new FilterSelection(),

      // options
      keyword_options: [],
      advertiser_options: [],
      status_options: [],
      layout_options: [],
      type_options: [],

      scope: SEARCH.PERSONAL,
      scopes: [SEARCH.PERSONAL, SEARCH.GROUP],

      //filters
      query: '',
      active_filter: null,

      items: {
        folders: null,
        campaigns: null,
        creatives: null,
      },

      meta: null,
      loaded: null,
      offset: {
        folders: 0,
        campaigns: 0,
        creatives: 0,
      },
      can_load: {
        folders: true,
        campaigns: true,
        creatives: true,
      },

      // admins can have id with scope
      id: null,
      users: null,

      BREADCRUMB,
    };
  },

  created() {
    this.$user.subscribe(user => {
      if (user) {
        this.is_admin = this.$user.adminMode();

        if (this.is_admin) {
          this.scopes.push(SEARCH.ADMIN);
        } else if (this.scope === SEARCH.ADMIN) {
          this.scope = SEARCH.PERSONAL;
          this.handleChanges();
        }
      }
    }, this);

    FilterService.load().then(_ => this.convertParamsToFilters());
    FilterService.subscribe(state => {
      if (state == null) return;

      this.layout_options = state['creatives.layout'];
      this.status_options = state.status;
      this.type_options = state['creatives.type'];
      this.keyword_options = state['campaigns.keywords'];
      this.advertiser_options = state['campaigns.advertisers'];
    }, this);

    UsersService.subscribe(users => {
      this.users = users;
    }, this);

    SearchService.active.subscribe(item => {
      this.updateItem(item);
    }, this);
  },

  mounted() {
    if (this.$sidebar) {
      this.$sidebar.setBack('My Campaigns', '/');
    }
  },

  methods: {
    async handleChanges() {
      if (!this.has_active_queries) {
        return this.$router.push({ name: VIEW.CAMPAIGNS });
      }

      const params = this.buildParams();
      const query = {
        scope: this.scope,
      };
      for (const key in params) {
        query[key] = params[key].join(',');
      }

      if (this.id && this.$user.isNexdAdmin()) {
        query.id = this.id;
      }

      this.$router.push({ name: VIEW.SEARCH, query }).catch(_ => {
        /** supress duplicated route errors */
      });
      this.resetView();
      await this.fetchResults();

      this.loadMoreFolders();
      this.loadMoreCampaigns();
      this.loadMoreCreatives();
    },

    resetView() {
      this.offset = {
        folders: 0,
        campaigns: 0,
        creatives: 0,
      };
      this.meta = null;
      this.loaded = null;
      this.items = {
        folders: null,
        campaigns: null,
        creatives: null,
      };
    },

    fetchResults(offset = 0) {
      this.loading = true;
      const params = this.buildParams();
      params.limit = LIMIT;
      params.offset = offset;
      params.scope = this.scope;

      if (this.id && this.$user.isNexdAdmin()) {
        params.id = this.id;
      }

      return this.$http
        .get(addFiltersToPath('search/advanced', params))
        .then(response => {
          if (this.loaded == null) {
            this.loaded = {};
          }
          if (!this.meta) {
            this.meta = response.meta;
          }
          this.$set(this.loaded, offset, response.result);

          // setup initial item arrays, when result contain those types of items
          const shouldUpdate = key => {
            return this.items[key] == null && response.result[key] != null;
          };

          if (shouldUpdate('folders')) {
            this.items.folders = [];
          }
          if (shouldUpdate('campaigns')) {
            this.items.campaigns = [];
          }
          if (shouldUpdate('creatives')) {
            this.items.creatives = [];
          }
        })
        .finally(_ => {
          this.loading = false;
        });
    },

    buildParams() {
      const params = { ...this.filter_selection.getParams() };
      if (this.query.trim() !== '') {
        params.search = [this.query.trim()];
      }
      return params;
    },

    async convertParamsToFilters() {
      const params = this.$route.query;

      if (params.search) {
        this.query = params.search.trim();
      }

      if (params.scope && this.scopes.includes(params.scope)) {
        this.scope = params.scope;
      }

      if (params.id) {
        this.id = params.id;
      }

      // make new objects, so when updating filters in service, it would not affect the values
      const layout_filters = {};
      const status_filters = {};
      const type_filters = {};
      const keyword_filters = {};
      const advertiser_filters = {};

      // setup filter objects
      for (const key in params) {
        if (key === 'creatives.template_id' || key === 'creatives.layout_id') {
          this.parseParams(params[key], 'creatives.layout', layout_filters);
        } else if (key === 'status') {
          this.parseParams(params[key], key, status_filters);
        } else if (key === 'creatives.type') {
          this.parseParams(params[key], key, type_filters);
        } else if (key === 'campaigns.keywords') {
          this.parseParams(params[key], key, keyword_filters);
        } else if (key === 'campaigns.advertisers') {
          this.parseParams(params[key], key, advertiser_filters);
        }
      }

      // set filter objects inside a service
      this.filter_selection.init({
        layouts: layout_filters,
        status: status_filters,
        types: type_filters,
        keywords: keyword_filters,
        advertisers: advertiser_filters,
      });

      // initial search
      this.resetView();
      await this.fetchResults();

      this.loadMoreFolders();
      this.loadMoreCampaigns();
      this.loadMoreCreatives();
    },

    parseParams(values, key, filters) {
      for (const value of values.split(',')) {
        const option = FilterService.getOption(value, key);

        if (option?.value != null && !filters[option.value]) {
          filters[option.value] = option;
        }
      }
    },

    searchHandler() {
      this.handleChanges();
    },

    clearSearch() {
      this.query = '';
      this.handleChanges();
    },

    clearId() {
      this.id = '';
      this.handleChanges();
    },

    clearFilter(group, filter, filter_type) {
      this.$delete(group, filter.value);
      this.updateFilterService(group, filter_type);
    },

    clearAllFilters() {
      this.filter_selection.reset();
      this.clearSearch();
    },

    updateFilterService(filters, filter_group = null) {
      if (filters == null) return;

      this.filter_selection.select(filter_group ?? this.active_filter, filters);

      this.handleChanges();
    },

    activeFilterChangeHandler(filters) {
      if (this.active_filter == null) return;
      this.updateFilterService(filters);
    },

    async loadMoreFolders() {
      if (this.loaded?.[this.offset.folders] == null) {
        await this.fetchResults(this.offset.folders);
      }

      if (this.items.folders == null) return;

      // push current offset of loaded info to the view
      if (this.loaded[this.offset.campaigns]?.folders) {
        this.items.folders.push(...this.loaded[this.offset.folders].folders);
        this.can_load.folders = this.loaded[this.offset.folders].folders.length >= LIMIT && this.offset.folders + LIMIT < this.meta.folders.total;

        // increatese offset for the next load more
        this.offset.folders += LIMIT;
      }
    },

    async loadMoreCampaigns() {
      if (this.loaded?.[this.offset.campaigns] == null) {
        await this.fetchResults(this.offset.campaigns);
      }

      if (this.items.campaigns == null) return;

      // push current offset of loaded info to the view
      if (this.loaded[this.offset.campaigns]?.campaigns) {
        this.items.campaigns.push(...this.loaded[this.offset.campaigns].campaigns);
        this.can_load.campaigns = this.loaded[this.offset.campaigns].campaigns.length >= LIMIT && this.offset.campaigns + LIMIT < this.meta.campaigns.total;

        // increatese offset for the next load more
        this.offset.campaigns += LIMIT;
      }
    },

    async loadMoreCreatives() {
      if (this.loaded?.[this.offset.creatives] == null) {
        await this.fetchResults(this.offset.creatives);
      }

      if (this.items.creatives == null) return;

      // push current offset of loaded info to the view
      if (this.loaded[this.offset.creatives]?.creatives) {
        this.items.creatives.push(...this.loaded[this.offset.creatives].creatives);
        this.can_load.creatives = this.loaded[this.offset.creatives].creatives.length >= LIMIT && this.offset.creatives + LIMIT < this.meta.creatives.total;

        // increatese offset for the next load more
        this.offset.creatives += LIMIT;
      }
    },

    updateItem(new_item) {
      if (new_item == null) return;

      const key = this.getItemsKeyFromItem(new_item);

      if (this.items[key] == null) return;

      for (let item of this.items[key]) {
        if (item.item_id === new_item.item_id) {
          Object.assign(item, new_item);
          break;
        }
      }

      SearchService.active.set(null);
    },

    getItemsKeyFromItem(item) {
      switch (item.item_type) {
        case LISTITEM.FOLDER:
          return 'folders';
        case LISTITEM.CAMPAIGN:
          return 'campaigns';
        case LISTITEM.CREATIVE:
          return 'creatives';
        default:
          return null;
      }
    },

    changeScope() {
      if (this.scope === SEARCH.ADMIN) {
        this.scope = SEARCH.PERSONAL;
      } else {
        this.scope = SEARCH.ADMIN;
      }

      this.handleChanges();
    },
  },
};
</script>
