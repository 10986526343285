import Subscriber from '@libs/Subscriber';
import { alert, confirm } from '@libs/alerts';
import StatusTraits from '@master/Traits/StatusTraits.vue';

import Payment from '@master/Services/PaymentService';
import $http from '@master/Services/HttpService';
import { PUBLISH_ERROR } from '@master/constants';

export default class CreativesExportService extends Subscriber {
  #creatives_ids = [];
  #tag_update_needed_creative_ids = new Set();

  #loading = {
    head: 'Validating publishing(s)…',
    body: 'Please wait while we validate your selected creative(s) settings and files. This may take a while...',
  };

  subscribe(cb, vnode = null) {
    super.subscribe(cb, vnode);
  }

  resetNeedTagUpdate(creative_id) {
    this.#tag_update_needed_creative_ids.delete(creative_id);
  }

  requestTagUpdate(creative) {
    if (!StatusTraits.methods.isDraft(creative.status)) {
      this.#tag_update_needed_creative_ids.add(creative.creative_id);
    }
  }

  startExporting() {
    this.#set({ loading: this.#loading, tag_update_needed_creative_ids: Array.from(this.#tag_update_needed_creative_ids) });
  }

  finishExporting() {
    this.#set({ loading: null });
  }

  openPublishingModal() {
    this.#set({ show_publishing_notification: true });
  }

  closePublishingModal() {
    this.#set({ show_publishing_notification: false });
  }

  openPreviewPublish() {
    this.#set({ show_publishing_modal: true });
  }

  showVideoExportingModal() {
    this.#set({ show_video_exporting_modal: true });
  }

  bulkExport(campaign_id, creatives_ids, is_selected_creatives_all_published) {
    this.startExporting();

    this.#creatives_ids = creatives_ids;

    return $http
      .post(`v2/campaigns/${campaign_id}/validate/publish`, { creatives: this.#creatives_ids }, { notification: false })
      .then(_ => {
        if (is_selected_creatives_all_published) {
          this.openPublishingModal();
          return true;
        }

        if (this.#creatives_ids?.length === 1 && this.#tag_update_needed_creative_ids.has(this.#creatives_ids[0])) {
          this.openPublishingModal();
          return true;
        }

        this.openPreviewPublish();
        return true;
      })
      .catch(exception => {
        this.#bulkActionErrorHandler(exception);
        return false;
      })
      .finally(_ => {
        this.finishExporting();
      });
  }

  bulkVideoExport(campaign_id, creatives_ids) {
    this.startExporting();

    this.#creatives_ids = creatives_ids;

    return $http
      .post(`v2/campaigns/${campaign_id}/validate/video/export`, { creatives: this.#creatives_ids }, { notification: false })
      .then(_ => {
        this.showVideoExportingModal();
      })
      .catch(exception => {
        this.#bulkActionErrorHandler(exception);
      })
      .finally(_ => {
        this.finishExporting();
      });
  }

  #set(data) {
    this.data = data;
  }

  async #bulkActionErrorHandler(exception) {
    const errors = exception?.response?.result?.errors ?? [];

    // generic error about user or subscription
    if (errors.length === 0 && exception?.message) {
      return alert(undefined, exception.message);
    }

    // single creative selection with an error, use the error message and title for alert modal
    if (errors.length === 1 || this.#creatives_ids.length === 1) {
      // only on one error, if one creative is selected
      const { info, code, message } = errors[0];

      const billing_errors = [PUBLISH_ERROR.BILLING_INFO, PUBLISH_ERROR.MISSING_CC, PUBLISH_ERROR.TRIAL_ENDED, PUBLISH_ERROR.SUBSCRIPTION_ENDED, PUBLISH_ERROR.TRIAL];

      // code only possible when user has access
      if (billing_errors.includes(code)) {
        const options = {
          buttons: [
            { type: 'link-primary', label: 'Cancel', action: false },
            { type: 'primary', label: 'Open Billing', action: true },
          ],
        };
        const result = await confirm(undefined, info, message, options);
        if (result) {
          this.router.push('/billing');
        }
        return;
      }

      alert(undefined, message, info);
      return;
    }

    // if multiple creatives are selected, combine errors into a single message
    if (this.#creatives_ids.length > 1) {
      // put together per creative errors
      let rows = [];
      for (const item of errors) {
        let strongmessage = item.error;
        if (item.name != null) {
          strongmessage = `${strongmessage} - ${item.name}`;
        }

        rows.push(`<strong class="text-left">${strongmessage}</strong>`);
        rows.push(`<p>${item.message}</p>`);
      }

      return alert(undefined, rows.join('<br>'));
    }

    // fallback
    if (exception.message) {
      return alert(undefined, exception.message);
    }
  }
}
