import { trimQuery } from '@helpers/Global';
import { DEFAULT_DATE_FORMAT } from '@master/constants';

export default class GroupBillingInfo {
  constructor(obj) {
    this.name = obj?.name ?? '';
    this.country = obj?.country ?? '';
    this.city = obj?.city ?? '';
    this.business_address = obj?.business_address ?? '';
    this.zip_code = obj?.zip_code ?? '';
    this.reg_nr = obj?.reg_nr ?? '';
    this.vat_code = obj?.vat_code ?? '';
    this.email = obj?.email ?? '';
    this.analytics_timezone = obj?.analytics_timezone ?? '';
    this.date_format = obj?.date_format ?? DEFAULT_DATE_FORMAT;
  }

  valid() {
    return Object.entries(this).every(([key, value]) => {
      /** Not required fields */
      if (['reg_nr', 'vat_code', 'analytics_timezone'].includes(key)) {
        return true;
      }
      return trimQuery(value ?? '').length > 0;
    });
  }

  equals(obj) {
    return Object.entries(this).every(([key, value]) => value === obj?.[key]);
  }
}
