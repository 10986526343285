<template lang="pug">
  div.quick-navigation(v-if="creative != null")
    template(v-for="(link, icon) in links")
      a.quick-navigation-option(
        v-if="link.visible"
        :href="link.href"
        target="_blank"
        @click.stop=""
      )
        i(aria-hidden="true" :class="icon")
        span {{ link.label }}
</template>

<script>
import CampaignMixins from '@cm/Views/Campaigns/Campaign/CampaignMixins.vue';

import { getURLForEnv } from '@helpers/Global';
import { DSP } from '@master/constants';

export default {
  name: 'CreativeQuickNavigation',
  mixins: [CampaignMixins],

  props: {
    creative: {
      type: Object,
      default: () => null,
    },
  },

  computed: {
    links() {
      return {
        'nexd-icon-32-analytics': {
          visible: this.creative?.analytics_url != null && this.$user.hasAnalyticsAccess() && this.creative?.dsp !== DSP.GOOGLEADS,
          href: getURLForEnv(this.creative?.analytics_url),
          label: 'Analytics',
          ga_key: 'analytics_quick',
        },
        'nexd-icon-32-preview': {
          visible: this.creative?.preview_url != null,
          href: this.creative?.preview_url,
          label: 'Preview',
          ga_key: 'preview_quick',
        },
      };
    },
  },
};
</script>
