<template lang="pug">
div(v-if="creative.settings != null")
  div(v-if="moat_tracking_check")
    img(:src="$cdn + 'dist/assets/moat-logo.svg'" alt="moat")
    div.color-gray-800.fs-14.mt-8.mb-32 MOAT tracking enabled for the creative by Nexd. MOAT tracking will include viewability reporting, impressions, and clicks.
      |  If your creative has a video then all video tracking will be enabled in MOAT reporting.
  div.flex.flex-align-center
    span(v-if="$store.get('quantum_state') === 'overview'") Creative
    span(v-else) {{ $store.get('quantum_state') }}
    span &nbsp;tracking
    i.nexd-icon-16-help.ml-8(aria-hidden="true" v-tooltip="{template: 'cm.trackingGlobal'}")
  TrackingRowHeader
  template(v-if="creative.settings.cta != null")
    TrackingRowComponent(
      :creative="creative"
      :isExpanded="isExpanded"
      :global="true"
      :actions="['all']"
      :sizeset="sizeset"
      @change="change('cta', $event, null)"
    )
  UIGroup.mt-24(v-if="!isVast() && (creative.template != null || creative.layout != null)" :title="ui_group_title" :collapsed="collapsed" tooltip="cm.trackingAssetSpecific")
    template(v-if="CTA_items.size > 0")
      TrackingRowHeader(:global="false")
      template(v-for="(item, index) in CTA_items")
        TrackingRowComponent(
          :key="item.asset_id || `${item.object_id}_${index}`"
          :creative="creative"
          :isExpanded="isExpanded"
          :asset_id="item.asset_id"
          :object="item.object || null"
          :name="item.name || `Overlay ${index + 1}`"
          :user_value="item.user_value"
          :actions="item.actions"
          :tooltip="item.tooltip || null"
          @change="change(item.object_id || 'cta', $event, item)"
        )
        TrackingRowComponent(
          v-for="(child, child_indx) in item.children"
          :key="`${item.object_id}_${index}_${child_indx}`"
          :creative="creative"
          :object="child.object"
          :name="child.name"
          :user_value="child.user_value"
          :actions="child.actions"
          :isOption="true"
          :tooltip="child.tooltip || null"
          :pii="item.object.personal_information"
          @change="change(item.object_id || 'cta', $event, child)"
        )
    AssetSpecificError(v-else)
</template>

<script>
import UIGroup from '@master/UI/UIGroup.vue';
import AssetTraits from '@cm/Views/Creatives/Traits/AssetTraits.vue';
import CreativeTraits from '@master/Traits/CreativeTraits.vue';

import { clone } from '@helpers/Global';
import LayoutHelper from '@helpers/Layouts';
import { ASSET } from '@master/constants';

import TrackingRowComponent from '@cm/Views/Creatives/Cards/Components/Tracking/CTA/TrackingRowComponent.vue';
import AssetSpecificError from '@cm/Views/Creatives/Cards/Components/Tracking/Components/AssetSpecificError.vue';
import TrackingRowHeader from '@cm/Views/Creatives/Cards/Components/Tracking/Components/TrackingRowHeader.vue';

import RequestLimiter from '@libs/RequestLimiter';
import CreativesService from '@master/Services/CreativesService';

export default {
  name: 'TrackingTab',
  mixins: [CreativeTraits],
  extends: AssetTraits,
  components: {
    TrackingRowComponent,
    AssetSpecificError,
    TrackingRowHeader,
    UIGroup,
  },

  props: {
    creative: Object,
    // on two state layouts, parent is first state, otherwise parent === creative
    parent: Object,

    isExpanded: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      CTA_items: [],
      collapsed: !this.$user.getSubscription().isEnterprise(),
    };
  },

  computed: {
    sizeset() {
      if (this.$store.get('quantum_state') !== 'overview') {
        return this.$store.get('quantum_state');
      }
      return null;
    },

    ui_group_title() {
      let title = '';
      if (this.$store.get('quantum_state') === 'overview') {
        title = 'Asset';
      } else {
        title = `${this.$store.get('quantum_state')} asset`;
      }
      return `${title} specific tracking`;
    },

    moat_tracking_check() {
      return this.creative?.settings?.tag?.moat_tracking ?? false;
    },

    force_show_assets() {
      return this.creative?.template?.template_base === 'split';
    },
  },

  created() {
    this.init();
  },

  methods: {
    init() {
      this.CTA_items = [];

      // VAST creatives have no asset specific tracking
      if (this.isVast()) return;

      this.constructCTAAssets();
      this.constructCTAObjects();
    },

    constructCTAAssets() {
      if (this.hasLayout()) {
        this.CTA_items = this.getLayoutCTAAssets();
      } else {
        this.CTA_items = this.getTemplateCTAAssets();
      }
    },

    constructCTAObjects() {
      if (this.creative.objects != null) {
        let added_root_elements = [];
        for (const object_id in this.creative.objects) {
          if (object_id.indexOf('questions') > 0 && this.creative.objects[object_id].data.questions != null) {
            for (const question_index in this.creative.objects[object_id].data.questions) {
              let __item = {
                object_id: object_id,
                name: `${this.$options.filters.StringNumberFilter(parseInt(question_index) + 1, true)} Question`,
                object: this.creative.objects[object_id].data.questions[question_index],
                actions: ['impression'],
                user_value: this.creative.objects[object_id].data.questions[question_index].question.text.value,
                children: [],
                tooltip: 'cm.trackingQuestion',
              };
              if (this.creative.objects[object_id].data.questions[question_index].choices != null) {
                for (const choice_index in this.creative.objects[object_id].data.questions[question_index].choices) {
                  __item.children.push({
                    name: 'Choice ' + (this.creative.objects[object_id].data.questions[question_index].choices[choice_index].other === true ? 'Other' : parseInt(choice_index) + 1),
                    object: this.creative.objects[object_id].data.questions[question_index].choices[choice_index],
                    user_value: this.creative.objects[object_id].data.questions[question_index].choices[choice_index].text.value,
                    actions: ['click'],
                    tooltip: 'cm.choiceQuestion',
                  });
                }
              }
              this.CTA_items.add(__item);
            }
          } else {
            const rootslot = LayoutHelper.getLayoutItemRootSlot(this.creative.layout_id, object_id);
            // If root has already been added to list, we are not adding it again
            if (rootslot?.tracking_actions == null || rootslot.tracking_actions.length < 1 || added_root_elements.indexOf(rootslot.slot_id) !== -1 || this.creative?.objects?.[object_id]?.data?.value == null) {
              continue;
            }
            added_root_elements.push(rootslot.slot_id);
            if (this.creative.objects[object_id].data.cta == null) {
              this.creative.objects[object_id].data.cta = {
                action: 'url',
                uri: '',
              };
            }
            if (this.creative.objects[object_id].data.impressiontracker == null) {
              this.creative.objects[object_id].data.impressiontracker = [];
              this.creative.objects[object_id].data.impressiontracker.push({
                action: rootslot.tracking_actions[0] === 'click' ? 'ctr' : rootslot.tracking_actions[0], // We do have totally fkd up action key's, prolly most common would be to use ctr and for instreams click
                uri: '',
              });
            }
            this.CTA_items.add({
              object_id: object_id,
              name: rootslot.name || '',
              object: this.creative.objects[object_id].data,
              actions: rootslot.tracking_actions || null,
              user_value: this.creative.objects[object_id].data.value,
              children: [],
            });
          }
        }
      }
    },

    getLayoutCTAAssets() {
      let items = [];
      for (const asset_id in this.creative.assets) {
        let asset = LayoutHelper.getLayoutItemRootSlot(this.creative.layout_id, asset_id);
        // Layout global module assets don't have root, so have to check just by item
        if (asset == null) {
          asset = LayoutHelper.getLayoutItem(this.creative.layout_id, asset_id);
        }

        if (asset?.tracking_actions?.length > 0) {
          items.push({ ...this.creative.assets[asset_id], name: asset.name, actions: asset.tracking_actions });
        }
      }

      return new Set(items);
    },

    getTemplateCTAAssets() {
      if (this.creative.template == null) return;
      let CTA_items = [];
      let main_assets = [];

      let main_template_assets_count = 0;

      for (const asset_id in this.creative.template.assets) {
        // clone template asset obj, since we modify it and dont need it to modify the real values used elsewhere
        const asset = clone(this.creative.template.assets[asset_id]);

        let real_asset_id = asset_id;

        // if size specifc sizeset is chosen, check for assets with that sizeset as well
        if (this.$store.get('quantum_state') !== 'overview') {
          real_asset_id += `_${this.$store.get('quantum_state')}`;

          // give that template asset an id with sizeset suffix, so we would send the correct cta object to the asset cta component
          asset.asset_id = real_asset_id;
        }

        if (this.isOverlay(asset.type) && this.isAdditional(asset.required)) {
          // globals have required 2 and type 2
          // be sure asset is uploaded before adding it tot he list
          if (this.creative.assets[real_asset_id] != null && this.creative.settings.cta.assets[real_asset_id] != null) {
            CTA_items.push({
              asset_id: asset.asset_id,
              name: asset.name || null,
              actions: ['click'],
            });
          }
        } else if (asset.required < 2 && asset.cta_enabled === true) {
          // only count ones with required 1
          if (asset.required === 1) {
            main_template_assets_count++;
          }

          // be sure asset is uploaded before adding it tot he list
          if ((this.creative.assets[real_asset_id] != null || this.creative.assets[asset_id] != null) && this.creative.settings.cta.assets[real_asset_id] != null) {
            let actions = ['impression'];
            if (this.creative?.assets?.[real_asset_id]?.duration > 0 || this.creative?.assets?.[asset_id]?.duration > 0) {
              actions = ['all'];
            }

            // special case, if endcard is uploaded show that in the tracking panel
            if (this.isEndcard(asset)) {
              CTA_items.push({
                asset_id: asset.asset_id,
                name: asset.name || null,
                actions: ['click', 'impression'],
              });
            } else {
              main_assets.push({
                asset_id: asset.asset_id,
                name: asset.name || null,
                actions: actions,
              });
            }
          }
        } else {
          // on any errors, check which assets are excluded
          // console.log(asset.name, asset.required, asset.cta_enabled);
        }
      }

      for (const asset_id in this.creative.additional_assets) {
        // all additionals exept custom gesture
        if (!this.creative.additional_assets[asset_id].custom && this.creative.additional_assets[asset_id].uri) {
          const asset = this.creative.additional_assets[asset_id];
          let actions = ['click'];

          // additional feed asset
          if (asset?.custom_type === ASSET.NORMAL) {
            actions = ['impression'];
          }

          if (asset?.duration > 0) {
            actions = ['all'];
          }

          CTA_items.push({
            asset_id: asset_id,
            name: asset.name || null,
            actions,
          });
        }
      }

      // only add main assets to the list, if theres more than 1 required asset
      if (main_template_assets_count > 1 || this.force_show_assets) {
        CTA_items = main_assets.concat(CTA_items);
      }

      return new Set(CTA_items);
    },

    change(id, data, item = null) {
      // instantly set object values
      if (id === 'cta') {
        if (item?.asset_id != null) {
          // set only specific assset
          this.$set(this.creative.settings[id].assets[item.asset_id], 'impressiontracker', data.assets[item.asset_id].impressiontracker);
        } else {
          // set global object
          this.$set(this.creative.settings[id], 'impressiontracker', data.impressiontracker);

          // responsive case
          if (data.quantum != null) {
            this.$set(this.creative.settings[id], 'quantum', data.quantum);
          }
        }
      } else if (item.object != null) {
        // can be any object or a child of an object
        // update the original reference given to the row component
        this.$set(item.object, 'impressiontracker', data['impressiontracker']);
      }

      RequestLimiter.hook(`trackingtab_objects.${id}`, () => {
        if (id === 'cta') {
          this.$http.put(`creative/${this.creative.creative_id}/settings/${id}`, { value: this.creative.settings[id] });
        } else {
          this.$http.put(`v2/creative/${this.creative.creative_id}/objects/${id}`, this.creative.objects[id]);
        }
      });

      CreativesService.export.requestTagUpdate(this.parent);
    },
  },

  watch: {
    'creative.assets': {
      handler() {
        this.init();
      },
    },
    'creative.objects': {
      handler() {
        this.init();
      },
    },
  },
};
</script>
