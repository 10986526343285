<template lang="pug">
div
  div.flex.gap-4.flex-wrap(:class="{'mb-12': has_macros}")
    span(v-if="has_macros") Added macros:
    Pill.with-remove-icon.text-none(
      v-for="(flight_macro, index) of flight?.condition_macros"
      :key="index"
      variant="keyword"
      v-tooltip="{value: macros.includes(flight_macro.id) ? 'Macro in use. Only unused macros can be removed' : null}"
    ) {{ flight_macro.macro_name }}
      RemoveIcon(:disabled="macros.includes(flight_macro.id)" @click="removeMacro(index, flight_macro)")
  Button(type="primary" label="Add New" outline @click="show_add_modal = true")
    template(v-slot:prefix)
      IconAdd
  FlightAddMacroModal(v-if="show_add_modal" @close="show_add_modal = false")
</template>

<script>
import FlightService from '@master/Services/FlightService';

import Button from '@master/UI/Buttons/Button.vue';
import IconAdd from '@master/UI/Buttons/Icons/IconAdd.vue';
import Pill from '@master/UI/Pill/Pill.vue';
import RemoveIcon from '@master/UI/RemoveIcon.vue';

import FlightAddMacroModal from '@cm/Views/Flights/Components/FlightHeader/FlightAddMacroModal.vue';

export default {
  name: 'FlightMacros',

  components: {
    Button,
    FlightAddMacroModal,
    IconAdd,
    Pill,
    RemoveIcon,
  },

  computed: {
    has_macros() {
      return this.flight?.condition_macros?.length > 0;
    },
  },

  data() {
    return {
      flight: null,
      macros: [],

      show_add_modal: false,
    };
  },

  created() {
    FlightService.subscribe(data => {
      this.flight = data?.flight;
      this.macros = data?.macros ?? [];
    }, this);
  },

  methods: {
    change() {
      FlightService.update(this.flight);
    },

    async removeMacro(index, macro) {
      if (this.macros.includes(macro.id)) {
        return;
      }

      if (!macro.can_delete) {
        await FlightService.save();
      }

      this.flight.condition_macros.splice(index, 1);

      this.$http
        .delete(`v2/flights/${FlightService.getFlightId()}/macros/${macro.id}`)
        .then(() => {
          setTimeout(() => {
            this.change();
          }, 100);
        })
        .catch(_ => {
          /** suppress errors */
        });

      this.change();
    },
  },
};
</script>
